<template>
	<div class="app-wrapper">
		<v-app-bar
      v-show="!get_is_navigation_hidden"
			absolule
			dense
			:color="checkDarkThemeForRoute ? '' : 'white'"
			height="60"
			class="box-shadow-theme main-app-header header-border"
			:class="{
        'box-shadow__ischat': checkCurrentTabIsChat
      }"
      :dark="checkDarkThemeForRoute"
		>
      <div class="d-flex align-center">
        {{/*Menu btn*/}}
        <v-app-bar-nav-icon
          v-if="checkPermit('kw_show_menu')"
          color="primary"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>

        {{/*Menu btn*/}}

        <div
          v-if="$vuetify.breakpoint.mdAndUp"
          class="ml-3 d-flex align-center"
        >
          <router-link
            :to="{name: 'Welcome' }"
            custom
          >
            <v-img height="21" width="36" src="@/assets/logo-kwizbot.svg"/>
          </router-link>
          <v-divider vertical height="28" class="ml-5 mr-5"></v-divider>
          {{/*Bot name*/}}
          <v-toolbar-title
            v-if="checkPermit('show_kw_active_bot_title')"
          >{{ active_bot_title }}</v-toolbar-title>
        </div>
      </div>

			<v-layout
				v-show="
					renderExtension
					&& extension.show
					&& checkCurrentPageWelcome
					&& !checkIsSettingsPage()
				"
        align-center
        class="menu-tab-links__wrapper"
			>
        <v-btn
          v-if="scrollLeftBtn"
          icon
          text
          plain
          small
          :ripple="false"
          @click="scrollMenuLeft()"
        ><v-icon>mdi-chevron-left</v-icon></v-btn>

        <div
          v-dragscroll.x="true"
          @dragscrollstart="dragStart"
          @dragscrollend="dragEnd"
          @click.capture="dragClick"
          class="menu-tab-links"
          :class="{
            'menu-tab-links__dark': checkDarkThemeForRoute,
          }"
          ref="scrolledTabMenu"
        >

          <template
            v-for="item in extension.tabs_items"
          >
            <router-link
              v-if="checkTabShow(item) && showMenuTabOnlyAdmin(item)"
              :key="item.type"
              :to="routerLinkTab(item)"
              v-slot="{ navigate, href , isActive, isExactActive}"
            >
              <v-btn
                text
                plain
                elevation="0"
                tile
                :class="{ 'active_tab_menu' : isExactActive || checkActiveTab(item) }"
                :href="href"
                @click="navigate"
              >
                <template
                  v-if="item.type === 'icon'"
                >
                  <v-icon
                    :color="isExactActive ? 'primary' : ''"
                  >{{ item.tab }}</v-icon>
                </template>
                <template v-else>
                  {{ item.tab }}
                </template>
              </v-btn>
            </router-link>
          </template>
        </div>

        <v-btn
          v-if="scrollRightBtn"
          icon
          text
          plain
          small
          :ripple="false"
          @click="scrollMenuRight()"
        ><v-icon>mdi-chevron-right</v-icon></v-btn>
			</v-layout>

<!--			<portal-target name="destination">-->
				<!--
                This component can be located anywhere in your App.
                The slot content of the above portal component will be rendered here.
                -->
<!--			</portal-target>-->

			<v-spacer v-if="$vuetify.breakpoint.mdAndUp" />

			{{/*Menu Account Right*/}}
			<div
        class="d-flex align-center"
        v-if="$vuetify.breakpoint.mdAndUp"
      >
        <!-- Is Convenient -->
        <IsConvenient
          :recording="snackBarDraggableOpen"
          @open-snack-bar="snackBarDraggableOpen = $event"
        />
        <!-- end Is Convenient -->

        <!-- Notifications -->
        <MenuNotifications />
        <!-- end Notifications -->

        <!-- Help -->
        <MenuHelp />
        <!-- end Help -->

				<v-menu
					min-width="200px"
					transition="slide-y-transition"
          offset-y
          nudge-bottom="6"
          bottom
          :dark="checkDarkThemeForRoute"
				>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              color="primary"
              depressed
              icon
              :title="userInfo?.email"
            >
              <v-avatar
                  height="34"
                  width="34"
                  min-width="34"
                  color="primary"
                >
                  <template v-if="userInfo?.user?.avatar_img">
                    <v-img
                      height="34"
                      width="34"
                      :src="userInfo.user.avatar_img"
                    ></v-img>

                   </template>
                  <template v-else>
                    <span class="white--text text-truncate">{{ getFirstOperatorLetters() }}</span>
                  </template>
                </v-avatar>
<!--              <v-icon>mdi-chevron-down</v-icon>-->
            </v-btn>
          </template>

          <v-list dense class="pb-4">
            <v-list-item class="custom-list-item">
              <v-list-item-title
                class="custom-list-item-title"
                style="font-weight: bold;"
              >
                {{ `${userInfo?.first_name} ${userInfo?.last_name}` }}
              </v-list-item-title>

              <v-list-item-title
                class="user-email"
              >
                {{ userInfo?.email }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <appLangSetting mode="small_btn"></appLangSetting>
            </v-list-item>

            <v-list-item class="justify-center">
              <v-btn
                class="custom-btn justify-start"
                @click="logout"
                color="primary"
                text
                depressed
              >
                <v-icon
                  left
                  class="mr-4 primary--text"
                >
                  $iconlogout
                </v-icon>
                {{ $t('header.logout') }}
              </v-btn>
            </v-list-item>
          </v-list>
				</v-menu>
			</div>

			{{/*Menu Account Right*/}}
		</v-app-bar>

		{{/*Menu list*/}}
		<v-navigation-drawer
			v-model="drawer"
			:absolute="$vuetify.breakpoint.mdAndUp"
			temporary
			class="main-menu-drawer"
			:fixed="$vuetify.breakpoint.smAndDown"
      :dark="checkDarkThemeForRoute"
		>
			{{/*mobile header*/}}
			<div
				class="
					menu-mob-header
					d-flex
					justify-space-between
					align-center
				"
        :class="{
          'menu-mob-header__dark': checkDarkThemeForRoute
        }"
				v-if="$vuetify.breakpoint.smAndDown"
			>
				<div class="d-flex align-center">
					<v-btn icon tile @click.stop="drawer = !drawer">
						<v-img
							max-height="40"
							max-width="40"
							src="@/assets/logo.png"
						></v-img>
					</v-btn>

					<v-divider vertical class="ml-5 mr-5"></v-divider>
					{{/*Bot name*/}}
					<v-toolbar-title
						v-if="checkPermit('show_kw_active_bot_title')"
						>{{ active_bot_title }}</v-toolbar-title
					>
				</div>

				<v-btn icon @click.stop="drawer = !drawer" color="primary">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</div>
			{{/*mobile header*/}}

      {{/* MENU LIST */}}
      <v-list
        subheader
        class="menu_list"
      >
        <v-list-item-group
          v-model="group"
          active-class="menu-item__active"
        >
          <template v-for="(item, i) in full_menu_items">
            <v-list-item
              :key="i"
              v-if="
                item.show
                && checkModuleOrTabPermit(item)
                && !checkMenuItemsModuleWithoutActiveBot(item)
                && item.module === 'OperatorPanel'
              "
              class="pa-0"
            >
              <v-list-item-title
                class="menu-item py-5 px-4"
                role="link"
                @click="routerLinkMenu(item)"
              >
                <div>
                  <v-icon
                    color="greyBlueLight"
                    v-if="item.icon"
                  >{{ item.icon }}</v-icon>
                </div>
                <span>{{ item.title }}</span>
                <div
                  class="notification"
                  v-if="item.module === 'OperatorPanel' && isNotRead()"
                ><v-icon small color="#5684eb">mdi-bell</v-icon></div>
              </v-list-item-title>
            </v-list-item>
          </template>

          <v-divider/>

          <v-subheader
            class="gray--text text-caption"
          >{{$t('modules.dialog.texts.settings.subhead_menu_active_bot')}}</v-subheader>

          <v-select
            :value="bot_settings.active_id"
            :items="botsSelect"
            class="mx-4 mt-4 mb-2 flex-grow-0"
            :label="$t('modules.dialog.texts.settings.default_bot')"
            hide-details
            filled
            outlined
            dense

            :background-color="checkDarkThemeForRoute ? '' : 'primaryBackground'"

            append-icon="mdi-chevron-down"
            @change="setActiveBot($event)"
            :dark="checkDarkThemeForRoute"
          ></v-select>

          <template v-for="(item, i) in full_menu_items">
            <v-list-item
              :key="i"
              v-if="
                item.show
                && checkModuleOrTabPermit(item)
                && checkMenuItemsModuleWithoutActiveBot(item, false)
                && item.module !== 'OperatorPanel'
              "
              class="pa-0"
            >
              <v-list-item-title
                class="menu-item px-4"
                role="link"
                @click="routerLinkMenu(item)"
              >
                <div>
                  <v-icon
                    color="greyBlueLight"
                    v-if="item.icon"
                  >{{ item.icon }}</v-icon>
                </div>
                <span>{{ item.title }}</span>
              </v-list-item-title>
            </v-list-item>
          </template>

          <v-divider/>

          <v-subheader
            class="gray--text text-caption"
          >{{$t('modules.dialog.texts.settings.subhead_settings_menu')}}</v-subheader>

          <v-list-item>
            <v-list-item-title
              class="menu-item"
              role="link"
              @click="$router.push(`/settings-page`)"
            >
              <div>
                <v-icon
                  color="greyBlueLight"
                >mdi-cog</v-icon>
              </div>
              <span>
                {{$t('modules.dialog.module_settings.settings')}}
              </span>
            </v-list-item-title>
          </v-list-item>

          <template v-for="(item, i) in full_menu_items">
            <v-list-item
              :key="i"
              v-if="
                item.show
                && checkModuleOrTabPermit(item)
                && checkMenuItemsModuleWithoutActiveBot(item)
              "
              class="pa-0"
            >
              <v-list-item-title
                class="menu-item px-4"
                role="link"
                @click="routerLinkMenu(item)"
              >
                <div>
                  <v-icon
                    color="greyBlueLight"
                    v-if="item.icon"
                  >{{ item.icon }}</v-icon>
                </div>
                <span>{{ item.title }}</span>
              </v-list-item-title>
            </v-list-item>
          </template>

        </v-list-item-group>
      </v-list>
      {{/* end MENU LIST */}}

			<template v-slot:append>
        <div class="menu-footer">
          {{/*mobile footer*/}}
          <div v-if="$vuetify.breakpoint.smAndDown">
            <v-layout justify-space-between align-center>
              <a
                class="v-btn v-btn--depressed custom-btn"
                @click="logout"
                color="primary"
                href="#"
              >
                <v-icon
                  class="mr-2 primary--text"
                >
                  $iconlogout
                </v-icon>
                <span
                  class="primary--text"
                >
                  {{ $t('header.logout') }}
                </span>
              </a>
              <appLangSetting mode="small"></appLangSetting>
            </v-layout>
          </div>

          <v-tooltip
            v-show="version"
            v-model="showVersionTooltip"
            right
            top
            max-width="320"
            content-class="menu--version"
            light
            :open-on-hover="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                outlined
                color="primary"
                link
                v-bind="attrs"
                v-on="on"
                class="menu--version__chip mt-2"
              >
                v{{ version }}
              </v-chip>
            </template>

            <div>
              <h4 class="mb-2" v-if="versionUpdated">{{$t('version.header_new_version')}}</h4>
              <h4 class="mb-2" v-else>{{$t('version.header_hello')}}</h4>

              <div>
                <small>{{$t('version.text_descr')}}
                  <a :href='returnNotionLink()' target="_blank" rel="noopener">
                    {{$t('version.notion_text')}}
                  </a>
                </small>
              </div>

              <v-btn
                v-if="false"
                x-small
                color="success"
                elevation="0"
                class="mt-3 mx-auto"
              >{{$t('version.btn_telegram_text')}}</v-btn>
            </div>
          </v-tooltip>

        </div>

			</template>
		</v-navigation-drawer>
		{{/*Menu list*/}}

		{{/*modules*/}}
		<!-- <v-sheet class="h-100-percent app-wrapper"  style="background-color: #F5F6FA;">

            {{/*Подключение модуля по имени. Имя можно задавать динамически*/}}
            {{/*AppDialog AppSettings AppCustom*/}}
            <template v-if="checkComponentShowPermit">
                <component v-if="modules_components[current_module]" v-bind:is="modules_components[current_module]" />
            </template>
            <template v-else>
                <div class="h-100-percent" style="background-color: #fff; text-align: center; padding-top: 50px;">
                    {{$t('app.access_denied')}}
                </div>
            </template>
        </v-sheet> -->

		<router-view v-if="dataReady"></router-view>

    <SnackBarDraggable
      v-if="snackBarDraggableOpen"
      :open.sync="snackBarDraggableOpen"
    />
	</div>
</template>

<script>
import Vue from 'vue';
import { extra_modules_list } from '@/modules/custom_modules_list';
import Dialog from '@/views/Dialog/Dialog.vue';
import Settings from '@/views/Settings/Settings.vue';
// import AxiosPreloaderAndResponse from '../components/AxiosPreloaderAndResponse.vue';
import { mapGetters } from 'vuex';
import UserService from '@/services/user.service';
import { version } from './../../../package';
import LangSetting from '@/views/Auth/LangSetting'
import { dragscroll } from 'vue-dragscroll'
import * as Sentry from '@sentry/vue';
import i18n from "@/lang/i18n";
import HELPERS from '@/modules/OperatorPanel/helpers';

import SnackBarDraggable from '@/components/SnackBarDraggable.vue';

//menus
import MenuHelp from "@/components/Modules/Menus/MenuHelp.vue";
import MenuNotifications from "@/components/Modules/Menus/MenuNotifications.vue";

import IsConvenient from '@/components/Modules/Menus/IsConvenient.vue';

const userService = new UserService();

//Базовые компоненты
let components = {
	AppDialog: Dialog,
	AppSettings: Settings,
  appLangSetting: LangSetting,
	// AppCustom: Custom,
	// AppBroadcast: Broadcast,
	// appAxiosPreloaderAndResponse: AxiosPreloaderAndResponse,
  MenuHelp,
  MenuNotifications,
  IsConvenient,
  SnackBarDraggable,
};

//Базовый объект названий компонентов для рендеринга
let modules_components = {
	dialog: 'AppDialog',
	settings: 'AppSettings',
	// broadcast: 'AppBroadcast',
	// custom: 'AppCustom'
};

let modules_tabs_items = {};

//Подключение компонентов из модулей
if (extra_modules_list) {
	//Цикл по модулям
	for (let module of extra_modules_list) {
		if (module && module.active && module.component_name) {
			try {
				//Поиск файла настроек
				let obj = require('@/modules/' +
					module.component_name +
					'/kwizbot_settings.js');
				if (obj && obj.default && obj.default.module_settings) {
					//Получение объекта настроек

					//Данные по табам в отдельный объект
					let module_settings = obj.default.module_settings;
					if (module_settings.tabs_items) {
						for (let module_name in module_settings.tabs_items) {
							if (
								module_name &&
								module_settings.tabs_items[module_name] &&
								module_settings.tabs_items[module_name]
									.tabs_items
							) {
								modules_tabs_items[module_name] =
									module_settings.tabs_items[
										module_name
									].tabs_items;
							}
						}
					}

					//Если есть компоненты
					// if(module_settings.components && module_settings.components.length > 0){
					//     //Цикл по списку компонентов и подключение их
					//     for(let component_obj of module_settings.components){
					//         if(component_obj && component_obj.name && component_obj.path){

					//             let component = require('./components/Modules/Extra/' + module.component_name + '/' + component_obj.path)

					//             if(component && component.default) {
					//                 //Добавление компонентов
					//                 components[component_obj.name] = component.default
					//                 //Добавление компонентов в объект компонентов для рендера}
					//                 modules_components[component_obj.name] = component_obj.name
					//             }
					//         }
					//     }
					// }
				}
			} catch (e) {
				//console.log(e);
			}
		}
	}
}

export default {
	name: 'App',

	components,

  directives: {
    dragscroll,
  },

	data: () => ({
		full_menu_items: [],
		version: null,
		modules_components,
		modules_tabs_items,
		drawer: false,
		group: null,
		renderExtension: true,

		tab: null,
		dataReady: false,

    //drag menu
    dragging: false,
    timer: null,

    isScrollableMenu: false,
    scrollLeftBtn: false,
    scrollRightBtn: false,
    showVersionTooltip: false,
    versionUpdated: false,

    userInfo: null,

    snackBarDraggableOpen: false,
	}),

  beforeMount() {
    this.checkLogin();
  },

  created() {
    this.$nextTick(() => {
      let tabMenu = this.$refs?.scrolledTabMenu;
      tabMenu.addEventListener('scroll', () => {
        this.actionScroll()
      });
    })
  },

  mounted: async function () {
		await this.$store.dispatch('loadData');
    await this.$store.dispatch('OpAxiosGetMyInfo').then((response) => {
      if (this.opIsSettings.length === 0) {
        this.$store.dispatch('opGetSettings')
      }
    });

		//Формирование меню
		// this.updateMenu();

		this.setCurrentModule(this.$route);
    this.getScrolledTabMenu();

		this.getVersion();

		this.dataReady = true;


    this.initSentry();
    this.initAnalythics()
	},

	methods: {

    getFirstOperatorLetters() {
      return HELPERS.getFirstLettersOperator(this.userInfo)
    },

    isNotRead() {
      if (this.opIsNotification) {
        return true;
      }

      if (!this.opChatRoomGroupsNew) {
        return false;
      }

      return this.opChatRoomGroupsNew.filter(g => g.dialogs.find(
          (d) => d.messages.filter((d) => !d.message_read).length
      ))?.length;
    },

    async setActiveBot(id) {
      //Set default active bot from route param
      await this.setCookieActiveBotSettings(id);
      //end Set default active bot from route param

      //Change router if params bot_id exist in URL
      if (this.$route?.params?.id) {
        const routData = {
          params: {id: id},
          model_name: null
        }

        if(this.$route?.name === 'bot_scenario_history') {
          routData.name = 'bot_scenario'
        }

        await this.$router.push(routData)
      }
      //end Change router if params bot_id exist in URL

      location.reload()

      //redraw elements after changing Current BOT(from select in menu)
      // await this.$store.dispatch('loadDialogData');
      // this.$nextTick(() => {
      //   this.$root.$emit('SvgArea')
      // })
		},

		checkLogin() {
			if (!Vue.$cookies.get('token_c')) {
				// window.location.href = new URL(window.location.origin + '/login/');
				this.$router.push({ name: 'Login' });
			}
		},

		updateMenu() {
			this.full_menu_items = this.menu_items;
			this.$addExtraModulesSettingsToMainMenu(this);
		},

		changeModule: function (module) {
			//Если сервер не активирован - доступны только настройки
			// if (module === 'dialog' && !this.all_bot_data_init.ready) {
			// 	this.$store.dispatch('updateAjaxDialog', [
			// 		true,
			// 		false,
			// 		this.$t(
			// 			'modules.dialog.texts.common.activate_bot_error_title'
			// 		),
			// 		this.$t(
			// 			'modules.dialog.texts.common.activate_bot_error_description'
			// 		),
			// 	]);
			// 	module = 'settings';
			// 	this.$router.push({ name: 'SettingsEngines' });
			// }

			this.$store.dispatch('setModule', module);

			//Определение типа таба, пути урла и тайтла документа из объекта роутов
			let tab_type_defined = false;
			if (this.modules_routes_obj && this.modules_routes_obj[module]) {
				for (let tab_type in this.modules_routes_obj[module]) {
					if (tab_type && this.modules_routes_obj[module][tab_type]) {
						let tab = this.modules_routes_obj[module][tab_type];

						//Если у таба есть урл и тайтл
						if (tab.title && tab.path) {
							//установка тайтла документа
							document.title = tab.title;
							//Смена урла в строке браузера
							// window.history.pushState(module, "title", '/'+tab.path);
						}
						tab_type_defined = true;
						// this.$store.dispatch('setTabType', tab_type);
					}
					break;
				}
			}

			//Если тип таба не был определен в модуле - получение первого доступного типа для текущего модуля
			if (
				!tab_type_defined &&
				this.current_module_tab_types &&
				this.current_module_tab_types[0]
			) {
				//Установка дефолтных значений
				document.title = 'Kwizbot';
				// window.history.pushState(module, "title", '/');
				// this.$store.dispatch('setTabType', this.current_module_tab_types[0]);
			}
		},

		checkModuleOrTabPermit: function () {
			// if(item && item.permits && item.permits.show){
			//     return this.checkPermit(item.permits.show)
			// }

			return true;
		},

		checkPermit: function () {
			return true;
		},

		logout: function () {
			userService.signOut().then(() => {
				Vue.$cookies.remove('token_e');
				Vue.$cookies.remove('token_c');

        // remove user from Sentry
        Sentry.configureScope((scope) => scope.setUser(null));

				// let url = new URL(window.location.origin + '/login/');
				// window.location.href = url;
        this.$nextTick( () => this.$router.push({ name: 'Login' }) )
			});
		},

		forceRerenderExtension() {
			this.renderExtension = false;

			this.$nextTick().then(() => {
				this.renderExtension = true;
			});
		},

		setCurrentModule(route) {
			const moduleName = route?.fullPath.split('/')[1];

			if (!moduleName) return;

			this.changeModule(moduleName);

			// this.$store.dispatch("setModule", moduleName);

      //Set default active bot from route param
      if(route?.params && route?.params?.id) {
        this.setCookieActiveBotSettings(route?.params?.id)
      }
      //end Set default active bot from route param
		},

    routerLinkMenu(item) {
      if (item.module === 'dialog') {
        if(
          this.$route.name === 'bot_scenario'
          && parseInt(this.$route.params.id) !== this.bot_settings.active_id
        ) {
          this.$router.push({name: 'bot_scenario', params: { id: this.bot_settings.active_id }})
          location.reload()
        } else if (this.$route.name !== 'bot_scenario'){
          this.$router.push({name: 'bot_scenario', params: { id: this.bot_settings.active_id }})
        }
      } else if(
        item.module === 'CustomData'
        || item.module === 'Polls'
        || item.module === 'BroadcastUsers'
        || item.module === 'Statistics'
        || item.module === 'FastLine'
        || item.module === 'Broadcast'
      ) {
        // this.$router.push(`/${item.route}`)
        this.$router.push({name: item.module, params: { id: this.$route?.params?.id || this.bot_settings.active_id }})
      } else {
        this.$router.push(`/${item.route}`)
      }
    },


    routerLinkTab(item) {
      if(
        this.current_module === 'customdata'
        || this.current_module === 'polls'
        || this.current_module === 'broadcast_users'
        || this.current_module === 'statistics'
        || this.current_module === 'fastline'
        || this.current_module === 'broadcast'
      ) {
        return `/${this.current_module}/${this.$route?.params?.id || this.bot_settings.active_id}/${item.route}`
      } else {
        return `/${this.current_module}/${item.route}`
      }
    },

    toggleDrawer(boolean) {
      this.drawer = boolean
    },

    checkMenuItemsModuleWithoutActiveBot(item, is_with = true) {
      let modulesWithout = [
        "settings",
        "OperatorPanel_settings",
        "FileManager",
        "Statistics_settings",
        "ActionJail",
      ];


      if(is_with) {
        //fix for settings-page
        modulesWithout = [
          // "settings",
          // "OperatorPanel_settings",
          "FileManager",
          "Statistics_settings",
          "ActionJail",
        ]

        return !!modulesWithout.includes(item.module)
      } else {
        return !modulesWithout.includes(item.module)
      }
    },

    async setCookieActiveBotSettings(id) {
      let $this = this;
      id = Number(id)

      if (
        sessionStorage.getItem('active_bot_settings') &&
        JSON.parse(sessionStorage.getItem('active_bot_settings')).active_id !== id
      ) {

        let botData = $this.bots.find((b) => b.id === id)
        let params = {
          active_id: botData.id,
          updatedAt: botData.updatedAt,
          json_data: botData.json_data
        };

        await this.$store.dispatch('_commitActiveBotCookies', params)
      }
    },
    checkActiveTab(item) {
      const itemRoute = item?.route?.toLowerCase();
      const routeName = (this.$route?.meta?.parent_route) ? this.$route.meta.parent_route : this.$route?.name.toLowerCase()

      return itemRoute === routeName
    },

    //Menu drag
    dragStart() {
      if(this.scrollLeftBtn || this.scrollRightBtn) {
        this.timer = setTimeout(() => (this.dragging = true), 100);
      }
    },
    dragEnd() {
      clearTimeout(this.timer);
      setTimeout(() => (this.dragging = false));
    },
    dragClick(event) {
      if (this.dragging) {
        event.preventDefault();
        event.stopPropagation();
      }
    },

    getScrolledTabMenu() {
      if (this.$refs?.scrolledTabMenu) {
        setTimeout(() => {
          let activeTab = document.getElementsByClassName('active_tab_menu')[0]
          if (activeTab) activeTab.scrollIntoView({ behavior: "smooth", inline: "center" })

          this.actionScroll()
        }, 200)
      }
    },
    //end Menu drag

    //Menu Scroll
    actionScroll() {
      this.$nextTick(() => {
        const tabMenuWidth = this.$refs?.scrolledTabMenu?.offsetWidth
        const isScrollable = (tabMenuWidth - this.$refs?.scrolledTabMenu?.scrollWidth) < 0;

        if (isScrollable){
          this.scrollLeftBtn = this.$refs?.scrolledTabMenu.scrollLeft > 0;
          this.scrollRightBtn = this.$refs?.scrolledTabMenu.scrollWidth - this.$refs?.scrolledTabMenu.scrollLeft > tabMenuWidth
        } else {
          this.scrollLeftBtn = this.scrollRightBtn = false
        }
      })
    },
    scrollMenuLeft() {
      let el = document.getElementsByClassName('menu-tab-links')[0]
      this._sideScroll(el,'left',25,150,10);
    },
    scrollMenuRight() {
      let el = document.getElementsByClassName('menu-tab-links')[0]
      this._sideScroll(el,'right',25,150,10);
    },
    _sideScroll(element,direction,speed,distance,step){
      let scrollAmount = 0;
      let slideTimer = setInterval(function(){
        if(direction === 'left'){
          element.scrollLeft -= step;
        } else {
          element.scrollLeft += step;
        }
        scrollAmount += step;
        if(scrollAmount >= distance){
          window.clearInterval(slideTimer);
        }
      }, speed);
    },
    //end Menu Scroll

    /**
     * @name initAnalythics
     * @description enable Clarity Web Analythic
     */
    initAnalythics() {
      let enabled = false

      if (this.get_instance_settings.length && this.get_instance_settings.find((s) => s.key === 'clarity_enabled')) {
        enabled = this.get_instance_settings.find((s) => s.key === 'clarity_enabled').value === '1'
      }
      if (
        enabled &&
        process.env.VUE_APP_CLARITY_KEY
      ) {
        (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", process.env.VUE_APP_CLARITY_KEY);
      }
    },

    initSentry() {
      const sentry_enabled = this.get_instance_settings.find((s) => s.key === 'sentry_enabled')?.value
      const sentry_logs_level = this.get_instance_settings.find((s) => s.key === 'sentry_logs_level')?.value
      const sentry_replays_enabled = this.get_instance_settings.find((s) => s.key === 'sentry_replays_enabled')?.value

      this.$initSentry(
        sentry_enabled,
        sentry_logs_level,
        sentry_replays_enabled
      )
    },

    showMenuTabOnlyAdmin(tabItem) {
      let show = true
      const availableType = [
        'instance-settings'
      ]

      if (
        tabItem?.type &&
        availableType.includes(tabItem?.type) &&
        this.currentUser?.role_id !== 1
      ) show = false

      return show
    },

    getVersion() {
      const localVersion = Vue.$cookies.get('product_version')

      //check if version increased
      if (localVersion && this.compareVersions(version, localVersion) === 1) {
        this.versionUpdated = true
        this.drawer = true

        setTimeout(() => {
          this.showVersionTooltip = true
        }, 500)
      }

      Vue.$cookies.set(
        'product_version',
        version,
        60 * 60 * 24 * 30
      );

      this.version = version
    },

    compareVersions(versionA, versionB) {
      const partsA = versionA.split('.').map(Number);
      const partsB = versionB.split('.').map(Number);

      for (let i = 0; i < Math.max(partsA.length, partsB.length); i++) {
        const partA = partsA[i] || 0;
        const partB = partsB[i] || 0;

        if (partA > partB) {
          return 1;
        } else if (partA < partB) {
          return -1;
        }
      }

      //is equal
      return 0;
    },

    returnNotionLink() {
      return `${i18n.t('version.notion_url')}#:~:text=${version}`
    },

    checkTabShow(item) {
      let isShow = item?.show

      //Check custom show from instance settings for Embed Statistics
      // if(item?.type === 'statistic_embed_pro1') {
      //   if (this.get_instance_settings.length && this.get_instance_settings.find((s) => s.key === 'statistic_pro_url')) {
      //     isShow = !!this.get_instance_settings.find((s) => s.key === 'statistic_pro_url').value
      //   } else {
      //     isShow = false
      //   }
      // }
      // if(item?.type === 'statistic_embed_custom1') {
      //   if (this.get_instance_settings.length && this.get_instance_settings.find((s) => s.key === 'statistic_custom_url')) {
      //     isShow = !!this.get_instance_settings.find((s) => s.key === 'statistic_custom_url').value
      //   } else {
      //     isShow = false
      //   }
      // }

      if(item?.type === 'statistic_real_time') {
        if (this.get_instance_settings.length && this.get_instance_settings.find((s) => s.key === 'statistic_real_time_url')) {
          isShow = !!this.get_instance_settings.find((s) => s.key === 'statistic_real_time_url').value
        } else {
          isShow = false
        }
      }
      //end Check custom show from instance settings for Embed Statistics

      // Check Role true Operator Helper
      if(item?.type === 'operator_helper') {
        isShow = this.$store.state.kwizbot.user.role.permissions.operatorPanel.common
          ?.find(({name}) => name === 'operator_helper')?.select?.haveRights ||
          this.$store.state.kwizbot.user?.root
      }
      // Check Role true Operator Helper

      return isShow
    },

    checkIsSettingsPage() {
      const settings_page = this.$route?.fullPath.split('/')[1];
      return settings_page === 'settings-page'
    }
	},

	computed: {
		...mapGetters([
			'menu_items',
			'current_module',
			'current_module_obj',
			'current_tab_type',
			'engine_settings',
			'bot_settings',
			'active_bot_title',
			'all_bot_data_init',
			'modules_routes_obj',
			'bots',
			'opChatRoomGroupsNew',
			'opIsNotification',
      'get_instance_settings',
      'opGetDarkThemeCookie',
      'get_is_navigation_hidden',
      'opIsSettings',
		]),

		botsSelect() {
			return this.bots.map((b) => {
				return {
					text: b.name,
					value: b.id,
				};
			});
		},

		activeBot() {

			if (!this.bot_settings.active_id && this.bots && this.bots.length > 0) {
				return this.bots.find((b) => b.active).id;
			}

			return +this.bot_settings.active_id;
		},

		currentUser() {
			return this.$store.state.kwizbot.user;
		},

		checkCurrentTabIsChat() {
			const isMob = this.$vuetify.breakpoint.smAndDown;
			const isChat = this.current_tab_type === 'chats';

			return isMob && isChat;
		},

    checkCurrentPageWelcome() {
      if (this.$route.name === 'Welcome') {
        this.toggleDrawer(true)
        return false
      } else {
        return true
      }
    },

		checkComponentShowPermit: function () {
			// if(this.modules_tabs_items && this.current_module && this.modules_tabs_items[this.current_module]){
			//     let module_tabs = this.modules_tabs_items[this.current_module]
			//     for(let tab of module_tabs){
			//         if(tab && tab.type && tab.type === this.current_tab_type && tab.permits && tab.permits.show){
			//             return this.checkPermit(tab.permits.show)
			//         }
			//     }

			// }

			return true;
		},

		//Массив всех текущих типов табов модуля
		current_module_tab_types: function () {
			let types = [];

			if (this.current_module_obj && this.current_module_obj.tabs_items) {
				for (let tab_item of this.current_module_obj.tabs_items) {
					if (tab_item && tab_item.type) {
						types.push(tab_item.type);
					}
				}
			}

			return types;
		},

		extension: function () {
      let extension = {
        show: false,
        tabs_items: [],
      };

      if (this.current_module && this.current_module_obj) {
        if (this.current_module_obj.use_tabs) {
            extension.show = true;
        }

        if (this.current_module_obj.tabs_items) {
          extension.tabs_items = this.current_module_obj.tabs_items;
          userService.filterTabsByExtention(
            this.current_module,
            extension.tabs_items,
          );
        }
      }

      // Update Name for Tabs from
      if(
        this.current_module === 'statistics'
        && extension?.tabs_items?.length
        && this.$getInstanceSettings('statistic_cc_pro_full')?.value
      ) {
        const item = extension.tabs_items.find(item => item.route === 'tables');

        if (item) item.tab = this.$t('modules.statistics.module_settings.tables');
      }
      // end Update Name for Tabs from

        return extension;
    },

		// tab: {
		//     // геттер:
		//     get: function () {
		//         return this.current_tab_type
		//     },
		//     // сеттер:
		//     set: function (val) {

		//         //Устанавливается значение только если оно есть в списке табов
		//         if(this.current_module_tab_types.includes(val)){

		//             //поиск урла и тайтла для переключения
		//             if(this.current_module && this.modules_routes_obj && this.modules_routes_obj[this.current_module]){
		//                 for(let tab_type in this.modules_routes_obj[this.current_module]){
		//                     if(tab_type && this.modules_routes_obj[this.current_module][tab_type]){
		//                         //Таб совпадает с указанным в сеттере
		//                         if(tab_type === val){
		//                             let tab = this.modules_routes_obj[this.current_module][tab_type]

		//                             //если указан тайтл документа и путь урла
		//                             if(tab.title && tab.path){
		//                                 //установка тайтла документа
		//                                 document.title = tab.title
		//                                 //установка урла в строке браузера
		//                                 window.history.pushState(module, "title", '/'+tab.path);
		//                             }
		//                             break
		//                         }
		//                     }
		//                 }
		//             }

		//             //Установка типа таба
		//             this.$store.dispatch('setTabType', val);
		//         }

		//     }
		// }

    checkDarkThemeForRoute() {
      return this.opGetDarkThemeCookie && this.$route?.name === 'OperatorPanelChats'
    },
	},

	watch: {
		current_module: function () {
			//сброс таба при переключении модуля
			this.tab = this.$route.path || null;
		},

		group() {
			this.drawer = false;
		},

		currentUser(user) {
			this.updateMenu();
			userService.setData(user, this.engine_settings, Vue.$cookies);

			this.full_menu_items = userService.filterMenu(this.full_menu_items);
			this.forceRerenderExtension();

			this.userInfo = user;


      //fix for redirect from login/scenario, to first available menu if Dialog hasn't the permission on User
      if (
        this.$route?.name === 'bot_scenario'
        && !this.$store.state.kwizbot.user?.role?.permissions?.scenario?.route?.haveRights
      ){
        const firstInMenu = this.full_menu_items.find((item) => item.show)

        this.$router.push({path: '/' + firstInMenu.route})
      }

		},

		// $route: 'setCurrentModule',
    $route(val) {
      this.getScrolledTabMenu();
      this.setCurrentModule(val);
    },
	},
};
</script>

<style lang="scss">
.app-wrapper {
	background-color: #f5f6fa;
	flex-grow: 1;
}
.overflow-hidden {
	overflow: hidden !important;
}
.h-100-vh {
	height: 100vh;

	@media #{map-get($display-breakpoints, 'sm-and-down')} {
		height: 100svh;
	}
}
.h-100-percent {
	height: 100%;
}
.w-100-percent {
	width: 100%;
}
.menu-item {
	min-height: 48px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	white-space: normal !important;

	// & > div{
	//     /*height: 100%;*/
	//     /*width: 25px;*/
	//     /*min-width: 25px;*/
	//     /*display: flex;*/
	//     /*align-items: center;*/
	//     /*justify-content: center;*/

	//     /*& > svg{*/
	//     /*    width: 100%;*/
	//     /*}*/
	// }

	& > span {
		display: block;
		margin-left: 20px;
		color: var(--v-greyPurple-base);
		font-size: 14px;

		@media screen and (max-width: 959px) {
			font-size: 13px;
		}
	}
}

.menu-item:hover {
	color: #5567ff;
}
.theme--light.v-divider {
	border-color: #d6dcfa !important;
}

/* common Classes */
.box-shadow-theme {
	box-shadow: 0px 5px 15px rgba(77, 76, 172, 0.12) !important;
}
.v-tooltip__content {
	background: #050038;
	opacity: 0.8;
	box-shadow: 0px 5px 15px rgba(77, 76, 172, 0.12);
	border-radius: 4px;
}

/*** Header ***/
.header-tabs {
	@media screen and (max-width: 959px) {
		.v-slide-group__prev--disabled,
		.v-slide-group__next--disabled {
			display: none !important;
		}

		width: calc(100% - 25px);
	}
}
/*** Header ***/

/*** mobile menu ***/
.main-menu-drawer {
  @media screen and (min-width: 959px) {
    height: 100vh !important;
  }

	@media screen and (max-width: 959px) {
		width: 100% !important;
	}

  .menu-footer {
    //box-shadow: 0px 9px 15px 15px rgba(77, 76, 172, 0.12);
    box-shadow: 0px -5px 15px rgba(77, 76, 172, 0.12);
    text-align: center;
    padding-bottom: 6px;
  }
}

.menu--version{
  border-radius: 0;
  background: white !important;
  border: 1px solid var(--v-primary-base);
  color: var(--v-black3-base) !important;
  line-height: 1.2;
  padding: 20px;
  pointer-events: auto !important;

  &.v-tooltip__content.menuable__content__active {
    opacity: 1;
  }

  &__chip {
    font-weight: 500;
  }
}

.menu-mob-header {
	height: 60px;
	width: 100%;
	box-shadow: 0px 5px 15px rgba(77, 76, 172, 0.12);
	background-color: #fff;
	padding: 0 10px 0 15px;

  &__dark {
    background-color: #272727;
    color: white;
  }
}

.app-wrapper {
	& > div > div > div:not(.v-overlay__content) {
		height: 100%;
	}
}

.box-shadow__ischat {
	position: relative;
	z-index: 2;
}
.header-op-title {
	font-weight: 500;
	font-size: 14px;
	color: var(--v-secondary);
	flex: 1;
}
.notification {
	max-width: 21px !important;
	display: flex !important;
	justify-content: flex-start !important;
    margin-left: auto;

	> i {
		animation: ring 1s ease-in-out infinite;

		@keyframes ring {
			0%,
			60% {
				transform: rotate(0);
			}
			20%,
			40% {
				transform: rotate(8deg);
			}
			30%,
			50% {
				transform: rotate(-8deg);
			}
		}
	}
}
</style>

<style lang="scss" scoped>
.main-app-header {
	overflow: hidden;
}
.menu-item__active {
	::v-deep .greyBlueLight--text {
		color: var(--v-primary-base) !important;
	}

	span {
		color: var(--v-primary-base);
	}
}
.menu_list::v-deep {
  .v-subheader {
    height: auto;
    padding-top: 16px;
  }
}

.menu-tab-links__wrapper {
  height: 100%;
  margin-left: 16px;
  @media screen and (max-width: 959px) {
    margin-left: 0;
    margin-right: -10px;
  }
}
.menu-tab-links {
  //margin: 0 0 0 16px;
  flex-grow: 1;
  height: 100%;
  max-width: 100%;
  display: flex;
  //justify-content: center;
  overflow: auto;

  //shadow on scroll
  background:
    linear-gradient(90deg, white 0%, rgba(255,255,255,0)),
    linear-gradient(-90deg, white 0%, rgba(255,255,255,0)) 100% 0,
    radial-gradient(
        farthest-side at -50% 50%,
        rgba(0,0,0,.2),
        rgba(0,0,0,0)
    ),
    radial-gradient(
        farthest-side at 150% 50%,
        rgba(0,0,0,.2),
        rgba(0,0,0,0)
    ) 100% 0;
  background-repeat: no-repeat;
  background-color: #fff;
  background-size: 100px 60px, 100px 60px, 14px 60px, 14px 60px;
  background-attachment: local, local, scroll, scroll;

  &::-webkit-scrollbar { display: none; }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &.menu-tab-links__dark {
    background:
      linear-gradient(90deg, #272727 0%, rgba(255,255,255,0)),
      linear-gradient(-90deg, #272727 0%, rgba(255,255,255,0)) 100% 0,
      radial-gradient(
          farthest-side at -50% 50%,
          rgba(0,0,0,.2),
          rgba(0,0,0,0)
      ),
      radial-gradient(
          farthest-side at 150% 50%,
          rgba(0,0,0,.2),
          rgba(0,0,0,0)
      ) 100% 0;
    background-color: #272727;
  }

  a{
    height: 100% !important;
    text-transform: inherit;
    font-size: 14px;

    &.active_tab_menu {
      color: var(--v-primary-base);
    }
  }
}

  .header-border {
    border-bottom: 1px solid #f2f2f2 !important;
  }

  .custom-btn {
    display: block;

    text-decoration: none;
    text-transform: none;
    padding: 14px;

    @media screen and (min-width: 1024px) {
      width: 100%;
    }
  }

  .custom-list-item {
    display: block;
    padding-right: 80px;
    padding-left: 32px;
  }

  .custom-list-item-title {
    padding-bottom: 2px;
    padding-top: 15px;
  }

  .user-email {
    padding-top: 5px;
    padding-bottom: 15px;
  }
</style>
