import HELPERS from '@/modules/OperatorPanel/helpers';
import Vue from 'vue';
import axios from '../../axios-middleware';
import i18n from '@/lang/i18n.js';
import kwizbotModuleSettings from '@/modules/OperatorPanel/kwizbot_settings.js';
import { v4 as uuid_v4 } from 'uuid';

const options = {
  // second: "2-digit",
  // minute: "2-digit",
  // hour: "2-digit",
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
};
const INTL = new Intl.DateTimeFormat('ru-RU', options);

const state = {
  operator_panel: {
    chatRooms: [],
    notification: false,
    activeChatGroup: null,
    activeChatRoom: null,
    serverTime: null,
    subjectList: [],
    subjectListTree: [],
    rebuildKey: Date.now(),

    chatRoomGroups: [],
    typeChatRooms: {type: '', id: ''},
    typeChatRoomsEnum: {
      all: 'all',
      unassigned: 'unassigned',
      my: 'my',
      operator: 'operator',
      subject: 'subject',
      closedChats: 'closedChats',
			queue: 'queue',
			channel: 'channel',
			bot: 'bot',
			status: 'status',
			tag: 'tag',
			ticket_assignee_my: 'ticket_assignee_my',
			outdated: 'outdated',
			resolved: 'resolved',
    },

		chatRoomFolders: [
			{
				"name": i18n.t('modules.op.texts.all'),
				"key": 'all',
				"order": 1,
				"enabled": true,
				"fixed": false,
				"icon": "mdi-all-inclusive",
				"type": "item",
				"default": false,
				"group_by_subjects": true,
			},
			{
				"name": i18n.t('modules.op.texts.unassigned'),
				"key": 'unassigned',
				"order": 2,
				"enabled": true,
				"fixed": false,
				"icon": "mdi-chat-alert-outline",
				"type": "item",
				"default": true,
				"group_by_subjects": true,
			},
			{
				"name": i18n.t('modules.op.texts.assigned_to_me'),
				"key": 'my',
				"order": 3,
				"enabled": true,
				"fixed": false,
				"icon": "mdi-chat-processing-outline",
				"type": "item",
				"default": false,
				"group_by_subjects": true,
			},
			{
				"name": i18n.t('modules.op.texts.ticket_assigned_to_me'),
				"key": 'ticket_assignee_my',
				"order": 4,
				"enabled": true,
				"fixed": false,
				"icon": "mdi-chat-processing-outline",
				"type": "item",
				"default": false,
				"group_by_subjects": true,
			},
			{
				"name": i18n.t('modules.op.texts.resolved'),
				"key": 'resolved',
				"order": 12,
				"enabled": true,
				"fixed": false,
				"icon": "mdi-chat-processing-outline",
				"type": "item",
				"default": false,
				"group_by_subjects": true,
			},
			{
				"name": i18n.t('modules.op.texts.outdated'),
				"key": 'outdated',
				"order": 13,
				"enabled": true,
				"fixed": false,
				"icon": "mdi-chat-processing-outline",
				"type": "item",
				"default": false,
				"group_by_subjects": true,
			},
			{
				"name": i18n.t('modules.op.texts.in_queue'),
				"key": 'queue',
				"order": 4,
				"enabled": true,
				"fixed": false,
				"icon": "mdi-account-multiple-outline",
				"type": "item",
				"default": false,
				"group_by_subjects": true,
			},

			{
				"name": i18n.t('modules.op.texts.by_operators'),
				"key": 'by_operators',
				"order": 5,
				"enabled": true,
				"fixed": false,
				"icon": "",
				"type": "group",
				"item_key": "operator",
				"default": false,
				"group_by_subjects": true,
			},
			{
				"name": i18n.t('modules.op.texts.by_subjects'),
				"key": 'by_subjects',
				"order": 6,
				"enabled": true,
				"fixed": false,
				"icon": "",
				"type": "group",
				"item_key": "subject",
				"default": false,
			},
			{
				"name": i18n.t('modules.op.texts.by_channel'),
				"key": 'by_channels',
				"order": 8,
				"enabled": true,
				"fixed": false,
				"icon": "",
				"type": "group",
				"item_key": "channel",
				"default": false,
				"group_by_subjects": true,
			},
			{
				"name": i18n.t('modules.op.texts.by_bots'),
				"key": 'by_bots',
				"order": 9,
				"enabled": true,
				"fixed": false,
				"icon": "",
				"type": "group",
				"item_key": "bot",
				"default": false,
				"group_by_subjects": true,
			},
			{
				"name": i18n.t('modules.op.texts.by_statuses'),
				"key": 'by_statuses',
				"order": 10,
				"enabled": true,
				"fixed": false,
				"icon": "",
				"type": "group",
				"item_key": "status",
				"default": false,
				"group_by_subjects": true,
			},
			{
				"name": i18n.t('modules.op.texts.by_tags'),
				"key": 'by_tags',
				"order": 11,
				"enabled": true,
				"fixed": false,
				"icon": "",
				"type": "group",
				"item_key": "tag",
				"default": false,
				"group_by_subjects": true,
			},
		],
		chatRoomFoldersTODO: [
			// {
			// 	"name": "Created By Me",
			// 	"key": 'created_by_me',
			// 	"order": 11,
			// 	"enabled": true,
			// 	"fixed": false,
			// 	"icon": "",
			// 	"type": "item",
			// },
			// {
			// 	"name": "Created Grouped",
			// 	"key": 'Created by Grouped',
			// 	"order": 12,
			// 	"enabled": true,
			// 	"fixed": false,
			// 	"icon": "",
			// 	"type": "item",
			// },
			// {
			// 	"name": "Internal",
			// 	"key": 'internal',
			// 	"order": 13,
			// 	"enabled": true,
			// 	"fixed": false,
			// 	"icon": "",
			// 	"type": "item",
			// },
		],


		chatRoomsSorting: 'default',
		chatRoomsSortingEnum: [
			{
				label: i18n.t('modules.op.texts.chat_rooms_menu.sorting.by_default'),
				value: "default",
			},
			{
				label: i18n.t('modules.op.texts.chat_rooms_menu.sorting.by_created'),
				value: "created",
			},
			{
				label: i18n.t('modules.op.texts.chat_rooms_menu.sorting.by_user'),
				value: "user",
			},
			{
				label: i18n.t('modules.op.texts.chat_rooms_menu.sorting.by_message_read'),
				value: "message_read",
			},

		],

    searchChatRoomsInput: '',

    groupByTheme: false,

    closedChatRoomsShow: false,

		closedRoomsSearchParamsDefault: {
			// order
			order: null,
			//bot_id
			bot_id: [],
			//kw_channel
			kw_channel: null,
			//users List
			operators: [],
			//skill_groups_ids
			skill_groups_ids: [],
			//subjects List
			subjects: [],
			//tags
			tags: [],
			//days toggle
			days: 7,
			//dates range
			from: new Date(new Date().setDate(new Date().getDate() - 6)).toISOString().slice(0, 10),
			to: new Date().toISOString().slice(0, 10),
			//clients text
			search: null,
			//groupBy
			group: null,
		},
    closedRoomsSearchParams: {
			// order
			order: null,
			//bot_id
			bot_id: [],
			//kw_channel
			kw_channel: null,
			//users List
			operators: [],
			//skill_groups_ids
			skill_groups_ids: [],
			//subjects List
			subjects: [],
			//tags
			tags: [],
      //days toggle
      days: 7,
      //dates range
      from: new Date(new Date().setDate(new Date().getDate() - 6)).toISOString().slice(0, 10),
      to: new Date().toISOString().slice(0, 10),
      //clients text
      search: null,
      //groupBy
      group: null,
    },
    closedRoomsSearchParamGroupByEnum: [
      {name: i18n.t('modules.op.search.item_operator'), value: "operator"},
      {name: i18n.t('modules.op.search.item_subject'), value: "subject"},
      {name: i18n.t('modules.op.search.item_date'), value: "date"},
      {name: i18n.t('modules.op.search.item_client'), value: "client"},
    ],
    closedRoomsSearch: [],
		tagsList: [],

		closedChatRoomData: null,

		bots_tokens: {},
  },
};

const mutations = {
  OP_SET_CHAT_ROOMS(state, chatRooms) {
    Vue.set(state.operator_panel, 'chatRooms', chatRooms);
  },

	OP_UPDATE_CHAT_ROOM_STATE(state, {index, chatRoom}) {
		if (index !== -1) {
			Vue.set(state.operator_panel.chatRooms, index, chatRoom);
		} else {
			state.operator_panel.chatRooms = [...state.operator_panel.chatRooms, chatRoom]
			state.operator_panel.chatRooms = state.operator_panel.chatRooms.sort((a, b) => a.id - b.id);
		}
	},

	OP_UPDATE_STATUS_CHAT_ROOM_STATE(state, {index, status}) {
		Vue.set(state.operator_panel.chatRooms[index], 'status', status);
	},
	OP_UPDATE_STATUS_NAME_CHAT_ROOM_STATE(state, {index, status}) {
		Vue.set(state.operator_panel.chatRooms[index], 'status_name', status);
	},
	OP_UPDATE_TAGS_CHAT_ROOM_STATE(state, {index, chatroom_tags}) {
		Vue.set(state.operator_panel.chatRooms[index], 'chatroom_tags', chatroom_tags);
	},
	OP_UPDATE_TYPE_CHAT_ROOM_STATE(state, {index, type}) {
		Vue.set(state.operator_panel.chatRooms[index], 'type', type);
	},
	OP_UPDATE_CHAT_ROOM_STATE_BY_KEY(state, {index, key, value}) {
		Vue.set(state.operator_panel.chatRooms[index], key, value);
	},

	OP_UPDATE_TYPE_CHAT_ROOM_TICKET(state, {index, chatroom_ticket}) {
		Vue.set(state.operator_panel.chatRooms[index], 'chatroom_ticket', chatroom_ticket);
	},

	OP_REMOVE_CHAT_ROOM_STATE(state, chat_room_id){
		const index = state.operator_panel.chatRooms.findIndex(obj => obj.id === chat_room_id);
		if (index !== -1) {
			state.operator_panel.chatRooms.splice(index, 1);
		}
	},

	OP_UPDATE_PARTICIPANT_CHAT_ROOM_STATE(state, { index, participants }){
		Vue.set(state.operator_panel.chatRooms[index], 'participants', participants);
	},

	OP_REMOVE_PARTICIPANT_CHAT_ROOM_STATE(state, {index, participants}){
		Vue.set(state.operator_panel.chatRooms[index], 'participants', participants);
	},

  OP_SET_ACTIVE_CHAT_GROUP(state, chatGroupId) {
    Vue.set(state.operator_panel, 'activeChatGroup', chatGroupId);
  },

  OP_SET_ACTIVE_CHAT_ROOM(state, chatRoomId) {
    Vue.set(state.operator_panel, 'activeChatRoom', chatRoomId);
  },

  OP_SET_CHAT_ROOM_PARTICIPANTS(state, chatRoom) {
    const participants = HELPERS.formatDialogParticipants(chatRoom);
    Vue.set(state.operator_panel.active_chat, 'participants', participants);
  },

  OP_SET_SERVER_TIME(state, dateStr) {
    state.operator_panel.serverTime = dateStr;
  },

  OP_SET_SUBJECT_LIST(state, subjectList) {
    state.operator_panel.subjectList = subjectList;
  },

  OP_SET_SUBJECT_LIST_TREE(state, subjectList) {
    state.operator_panel.subjectListTree = subjectList;
  },

  OP_SET_ACTIVE_CHAT_ROOM_URL(state, chatRoomId) {
    const menuItem = kwizbotModuleSettings.module_settings.menu_items[0];

    let url = `/${menuItem.route}/chats`;

    if (chatRoomId) {
      url += `?chat_room_id=${chatRoomId}`;
    }

    // enable/disable add chats?chat_id to url
    window.history.pushState(menuItem.title, 'title', url);
  },

  OP_SET_REBUILD_KEY(state, rebuildKey) {
    state.operator_panel.rebuildKey = rebuildKey;
  },

  OP_SET_CHAT_NOTINICATION(state, message) {
    const { chatRooms } = state.operator_panel;
    const room = chatRooms.find((v) => v.id === message?.data?.chat_room_id);

    state.operator_panel.notification = true;

    if (room) {
      const activeMessage = room.messages.find((v) => v.id === message.data.id);

      if (activeMessage) {
        activeMessage.message_read = message.data.message_read;
      } else {

        //add to message param "send_datetime" from "updatedAt"
        if ( !message.data?.send_datetime )
          message.data['send_datetime'] = message.data.updatedAt.replace('T', ' ').split('.')[0]

        room.messages.push(message.data);
      }
    }
  },
  OP_ADD_MESSAGE_TO_ACTIVE_CHATROOMS (state, message) {
    const { chatRooms } = state.operator_panel;
    const room = chatRooms.find((v) => v.id === message?.data?.chat_room_id);

    state.operator_panel.notification = false;

    if (room) {
      //add to message param "send_datetime" from "updatedAt"
      if ( !message.data?.send_datetime )
        message.data['send_datetime'] = message.data.updatedAt.replace('T', ' ').split('.')[0]

      message.data.message_read = true

      room.messages.push(message.data);

			//push to active dialog One msg
			const userId = state.operator_panel.userInfo.id;
			const options = { messages: [message.data], userId };
			// Find the index where the object should be inserted
			const index = state.operator_panel.active_chat.messageList
				.findIndex(item => item.id > message.data.id);
			// If no larger ID is found, index will be -1, and we should push at the end of the array
			if (index === -1) {
				state.operator_panel.active_chat.messageList.push(HELPERS.formatDialogMsgs(options)[0]);
			} else {
				state.operator_panel.active_chat.messageList.splice(index, 0, HELPERS.formatDialogMsgs(options));
			}
			// state.operator_panel.active_chat.messageList = [...state.operator_panel.active_chat.messageList, ...HELPERS.formatDialogMsgs(options)];
    }
  },

	OP_ADD_MESSAGE_TO_CHATROOM(state, message) {
		const { chatRooms } = state.operator_panel;
		const room = chatRooms.find((v) => v.id === message?.data?.chat_room_id);

		state.operator_panel.notification = false;

		if (room) {
			//add to message param "send_datetime" from "updatedAt"
			if ( !message.data?.send_datetime )
				message.data['send_datetime'] = message.data.updatedAt.replace('T', ' ').split('.')[0]

			// message.data.message_read = true

			room.messages.push(message.data);

			// set all message read in chatRoom
			room.messages.forEach((r) => (r.message_read = true));
		}
	},

  OP_SET_CHAT_READ(state, chatId) {
    const { chatRooms } = state.operator_panel;
    const room = chatRooms.find((v) => v.id === chatId);

    state.operator_panel.notification = false;

    if (room) {
      room.messages.forEach((r) => (r.message_read = true));
    }
  },

  OP_SET_TYPE_CHAT_ROOMS(state, typeChatRoomsVal) {
    state.operator_panel.typeChatRooms = typeChatRoomsVal
  },

  OP_SET_GROUP_BY_THEME(state, groupByTheme) {
    state.operator_panel.groupByTheme = groupByTheme
  },

  OP_SET_CHAT_ROOM_GROUPS(state, chatRoomGroups) {
    state.operator_panel.chatRoomGroups = chatRoomGroups
  },

  OP_SET_CLOSED_CHAT_ROOMS_SHOW(state, value) {
    state.operator_panel.closedChatRoomsShow = value
  },

  OP_SET_SEARCH_CHAT_ROOMS_INPUT(state, value) {
    state.operator_panel.searchChatRoomsInput = value
  },

  OP_SET_CLOSED_ROOMS_SEARCH(state, value) {
    state.operator_panel.closedRoomsSearch = value
  },

  OP_SET_CLOSED_ROOMS_SEARCH_PARAMS(state, value) {
    Vue.set(
      state.operator_panel.closedRoomsSearchParams,
      Object.keys(value)[0],
      value[Object.keys(value)[0]]
    )
  },

	OP_SET_CLOSED_ROOMS_SEARCH_PARAMS_TO_DEFAULT(state) {
		state.operator_panel.closedRoomsSearchParams = JSON.parse(JSON.stringify(state.operator_panel.closedRoomsSearchParamsDefault))
	},

	OP_SET_TAGS_LIST(state, payload) {
		state.operator_panel.tagsList = payload
	},

	OP_SET_CLOSED_CHAT_ROOM_DATA(state, data) {
		state.operator_panel.closedChatRoomData = data
	},
	OP_SET_CLOSED_CHAT_ROOM_DATA_BY_VALUE(state, value) {
		Vue.set(
			state.operator_panel.closedChatRoomData,
			Object.keys(value)[0],
			value[Object.keys(value)[0]]
		)
	},

	OP_SET_CHAT_ROOM_FOLDERS(state, data) {
		state.operator_panel.chatRoomFolders = data
	},

	OP_CHAT_ROOMS_SORTING(state, value) {
		state.operator_panel.chatRoomsSorting = value
	},
};

const actions = {
  opSetChatNotification({ commit }, data) {
    commit('OP_SET_CHAT_NOTINICATION', data);
  },
  opAddMessageToActiveChatRooms({ commit }, data) {
    commit('OP_ADD_MESSAGE_TO_ACTIVE_CHATROOMS', data);
  },

	opAddMessageToChatRoom({ commit }, data) {
		commit('OP_ADD_MESSAGE_TO_CHATROOM', data);
	},

  opSetChatReed({ commit }, data) {
    commit('OP_SET_CHAT_READ', data);
  },

  async OpAxiosGetChatRoomsRoot({ commit }, query) {
    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/chat_room/list/`;

    const params = query ? { params: query } : null;

    const response = await axios.get(url, params).catch((err) => {
      HELPERS.handleError([
        err,
        i18n.t('common.error'),
        i18n.t('common.error_get'),
      ]);
      return null;
    });

    return response;
  },

  async OpAxiosGetChatRooms({ commit, dispatch }) {
    const response = await dispatch('OpAxiosGetChatRoomsRoot');

    if (response) {
      const serverTime = response.data?.data?.current_datetime || null;
      const chatRooms = response.data?.data?.chat_rooms || [];

      commit('OP_SET_SERVER_TIME', serverTime);
      commit('OP_SET_CHAT_ROOMS', chatRooms);

      dispatch('opSetChatRoomGroups', chatRooms);
    } else {
      commit('OP_SET_CHAT_ROOMS', []);
      commit('OP_SET_CHAT_ROOM_GROUPS', [])
    }
  },

	OpUpdateChatRoomState({ state, commit }, chatRoom){
		let prepareChatRoom = {...chatRoom}
		//add empty participants
		if (!prepareChatRoom?.participants) {
			prepareChatRoom['participants'] = []
		}
		//add empty messages
		if (!prepareChatRoom?.messages) {
			prepareChatRoom['messages'] = []
		}
		const index = state.operator_panel.chatRooms.findIndex(obj => obj.id === chatRoom.id);

		commit('OP_UPDATE_CHAT_ROOM_STATE', {
			index: index,
			chatRoom: prepareChatRoom,
		})
	},

	OpUpdateStatusChatRoomState({ state, commit }, chatRoom) {
		const index = state.operator_panel.chatRooms.findIndex(obj => obj.id === chatRoom.id);

		if (index !== -1) {
			if(chatRoom?.status) {
				commit('OP_UPDATE_STATUS_CHAT_ROOM_STATE', {
					index: index,
					status: chatRoom.status
				})

				commit('OP_UPDATE_STATUS_NAME_CHAT_ROOM_STATE', {
					index: index,
					status: chatRoom.status_name
				})
			}

			if(chatRoom?.type) {
				commit('OP_UPDATE_TYPE_CHAT_ROOM_STATE', {
					index: index,
					type: chatRoom.type
				})

				commit('OP_UPDATE_STATUS_NAME_CHAT_ROOM_STATE', {
					index: index,
					status: chatRoom.status_name
				})
			}

			commit('OP_UPDATE_TAGS_CHAT_ROOM_STATE', {
				index: index,
				chatroom_tags: chatRoom.chatroom_tags
			})

			//update ticket data in chatRoom
			if(chatRoom?.chatroom_ticket) {
				commit('OP_UPDATE_TYPE_CHAT_ROOM_TICKET', {
					index: index,
					chatroom_ticket: chatRoom.chatroom_ticket
				})
			}

			commit('OP_UPDATE_CHAT_ROOM_STATE_BY_KEY', {
				index: index,
				key: 'kw_chat_title',
				value: chatRoom.kw_chat_title
			})


		}
	},
	OpRemoveChatRoomState({ commit }, chat_room_id){
		commit('OP_REMOVE_CHAT_ROOM_STATE', chat_room_id)
	},
	OpUpdateParticipantChatRoomState({ state, commit }, data) {
		const { chat_room_id, user_id, client, participant_id } = {...data}
		const index = state.operator_panel.chatRooms.findIndex(obj => obj.id === chat_room_id);

		if (index !== -1) {
			let participants = JSON.parse(JSON.stringify(state.operator_panel.chatRooms[index]?.participants))

			if(user_id) {
				if( !participants.some((user) => user?.user_id && user?.user_id === user_id) ) {
					const currentUser = state.operator_panel.fastUsersList?.find(({id}) => id === user_id)

					let userObj = {
						type: "user",
						id: participant_id,
						user: JSON.parse(JSON.stringify(currentUser)),
						user_id: user_id,
					}

					participants = [...participants, userObj]
				}
			}

			if (client) {
				if( !participants.some((user) => user?.client_id && user?.client_id === client.id) ) {
					const clientObj = {
						type: "client",
						id: participant_id,
						client: client,
						client_id: client.id,
					}

					participants = [...participants, clientObj]
				}
			}

			commit('OP_UPDATE_PARTICIPANT_CHAT_ROOM_STATE', {
				index: index,
				participants: participants,
			})
		}

	},
	OpRemoveParticipantChatRoomState({ state, commit }, data) {
		const { chat_room_id, user_ids, client_id } = {...data}
		const index = state.operator_panel.chatRooms.findIndex(obj => obj.id === chat_room_id);

		if (index !== -1) {
			let participants = JSON.parse(JSON.stringify(state.operator_panel.chatRooms[index]?.participants))

			if(user_ids?.length) {
				for(const id of user_ids){
					if( participants.some((user) => user?.user_id && user?.user_id === id) ) {
						const indexUser = participants.findIndex(obj => obj.user_id === id);
						participants.splice(indexUser, 1);
					}
				}
			}

			if(client_id) {
				if( participants.some((user) => user?.client_id && user?.client_id === client_id) ) {
					const indexUser = participants.findIndex(obj => obj.client_id === client_id);
					participants.splice(indexUser, 1);
				}
			}


			commit('OP_REMOVE_PARTICIPANT_CHAT_ROOM_STATE', {
				index: index,
				participants: participants,
			})
		}



	},

  /**
   * Create new chat room
   * @method OpAxiosCreateChatRoom
   * @param chatRoomTitle
   *
   */
  async OpAxiosCreateChatRoom(
    { state, commit, dispatch },
    { chatRoomTitle, number, channel, clientId, type, botId, fromOperator }
  ) {
    if (number) number = number.replace(/[()\s\-.]/g, '');
    else number = '';

    let chat_room_request = {
      kw_channel: channel || 'kwizbot',
      kw_chat_id: number || uuid_v4(),
      subject_id: null,
      bot_id: botId || state?.bot_settings?.active_id,
      kw_chat_title: chatRoomTitle || null,
      type: type || null,
      from_operator: fromOperator || false,
    };

    /** СОЗДАЕМ НОВУЮ КОМНАТУ **/

    let url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/chat_room/create/`;

    const {
			chat_room_id,
			existed,
			participants
		} = await axios
      .post(url, chat_room_request)
      .then((response) => {
        if (
          response.data &&
          response.data.data &&
          response.data.data.chat_room_id
        ) {
          return response.data.data;
        } else return 0;
      })
      .catch((err) => {
        HELPERS.handleError([
          err,
          i18n.t('common.error'),
          i18n.t('common.error_modify'),
        ]);
        return false;
      });

    if (!chat_room_id) {
      return 0;
    }

    /**ПОДКЛЮЧАЕМ ОПЕРАТОРА и псевдоклиента В ЧАТ СРАЗУ**/
    let participants_add_request = {
      chat_room_id,
      participants: [
        // {
        //   type: 'user',
        //   user_id: currentUserId,
        // },
        /* {
                "type": "client",
                "user_id":  client_id
            }*/
      ],
    };

		const currentUserId = state.operator_panel.userInfo.id;
    if (clientId) {
      participants_add_request.participants.push({
        type: 'client',
        client_id: clientId,
      });
    }

    url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/chat_room/participants/add/`;

		if(
			!existed &&
			currentUserId &&
			!participants?.length
		) {
			participants_add_request.participants.push({
				type: 'user',
				user_id: currentUserId,
			});

			await axios.post(url, participants_add_request).catch((err) => {
				HELPERS.handleError([
					err,
					i18n.t('common.error'),
					i18n.t('common.error_modify'),
				]);
				return false;
			});
		}

    return {
			chat_room_id,
			existed,
			participants,
		};
  },

	async OpAxiosCreateCustomChatRoom({dispatch}, data) {
		let query = {
			channel: data?.channel,
			number: data?.kw_chat_id,
			subject_id: data?.subject_id,
			botId: data?.botId,
			chatRoomTitle: data?.chatRoomTitle,
			type: data?.type,
			fromOperator: data?.fromOperator || null,

			clientId: data?.clientId || null,
		};

		const {
			chat_room_id,
		} = await dispatch(
			'OpAxiosCreateChatRoom',
			query
		);

		if (chat_room_id) {
			await dispatch('updateAjaxDialog', [
				true,
				false,
				i18n.t('common.success'),
				i18n.t('modules.op.texts.chat_created'),
			])
		}

		return chat_room_id
	},

  async opGetSudjectList({ commit }) {
    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/subjects/list/`;

    const subjects = await axios
      .get(url)
      .then((response) => {
        let subjectsList = response.data?.data?.subjects || []

        if (subjectsList.length) {
          subjectsList = subjectsList.filter( ({is_deleted}) => !is_deleted)
        }
        return subjectsList;
      })
      .catch((err) => {
        HELPERS.handleError([
          err,
          i18n.t('common.error'),
          i18n.t('common.error_get'),
        ]);
        return [];
      });

    commit('OP_SET_SUBJECT_LIST', subjects);
  },

  async opGetSudjectListTree({ commit }) {
    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/subjects/listTree/`;

    const subjects = await axios
      .get(url)
      .then((response) => {
        return response.data?.data?.subjects || [];
      })
      .catch((err) => {
        HELPERS.handleError([
          err,
          i18n.t('common.error'),
          i18n.t('common.error_get'),
        ]);
        return [];
      });

    commit('OP_SET_SUBJECT_LIST_TREE', subjects);
  },

  async opAxiosChangeChatRoomTheme({ dispatch }, query) {
    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/chat_room/subject/change/`;

    const { roomId, subjectId } = query;

    const request = {
      chat_room_id: roomId,
      subject_id: subjectId,
    };

    const success = await axios
      .post(url, request)
      .then((response) => {
        // if (response.data?.status === 'success') dispatch('OpAxiosGetChatRooms')
        return response.data?.status === 'success';
      })
      .catch((err) => {
        HELPERS.handleError([
          err,
          i18n.t('common.error'),
          i18n.t('common.error_modify'),
        ]);
        return false;
      });

    return success;
  },

  opSetActiveChatGroup({ commit }, chatGroupId) {
    commit('OP_SET_ACTIVE_CHAT_GROUP', chatGroupId);
  },

  opSetActiveChatRoom({ commit, getters }, chatRoomId) {
    commit('OP_SET_ACTIVE_CHAT_ROOM', chatRoomId);

    const activeChatRoom = getters.opActiveChatRoom;

    commit('OP_SET_CHAT_ROOM_PARTICIPANTS', activeChatRoom);

    commit(
      'OP_SET_ACTIVE_CHAT_ROOM_URL',
      chatRoomId || activeChatRoom?.id || null
    );

    commit('OP_SET_CHAT_READ', chatRoomId || activeChatRoom?.id || null);
  },
	opSetActiveChatRoomById({getters, dispatch}, chatRoomId) {
		// dispatch('OpAxiosGetChatRoomClientId', chatRoomId)
		dispatch('opSetActiveChatRoom', chatRoomId)

		const participants = getters.opActiveChatRoom?.participants || [];
		const isClient = (participant) => participant.type === 'client';
		const client = participants.find(isClient) || {};

		dispatch('opAxiosGetDialogInfo', chatRoomId)
		dispatch('opGetClientInfo', client.client_id)
		dispatch('opGetClientHistory', client.client_id)

		dispatch('opSetTopSidebarOpen', true)
		dispatch('opSetRightSidebarOpen', true)

		setTimeout(() => {
			dispatch('OpAxiosGetChatRoomMessages', chatRoomId);
		}, 250);
	},

  opSetActiveClosedChatRoom({ commit, getters }, data) {
    commit('OP_SET_ACTIVE_CHAT_ROOM', data.chat_room_id);
		commit('OP_SET_CLOSED_CHAT_ROOM_DATA', data)

    // const activeChatRoom = getters.opActiveChatRoom;

    // commit('OP_SET_CHAT_ROOM_PARTICIPANTS', activeChatRoom);

    // commit('OP_SET_CHAT_READ', chatRoomId || activeChatRoom?.id || null);
  },

  opClearActiveChatRoomOnDestroy({ commit, getter }, chatRoomId) {
    commit('OP_SET_ACTIVE_CHAT_ROOM', chatRoomId);

    const activeChatRoom = getters.opActiveChatRoom;

    commit('OP_SET_CHAT_ROOM_PARTICIPANTS', activeChatRoom);

    commit('OP_SET_CHAT_READ', chatRoomId || activeChatRoom?.id || null);
  },

  opSetTypeChatRooms ( {commit, getters}, typeChatRoomsVal ) {
    //check if closedChat active
    (typeChatRoomsVal.type === getters.opTypeChatRoomsEnum.closedChats )
      ? commit('OP_SET_CLOSED_CHAT_ROOMS_SHOW', true)
      : commit('OP_SET_CLOSED_CHAT_ROOMS_SHOW', false)

    commit('OP_SET_TYPE_CHAT_ROOMS', typeChatRoomsVal)
  },

  opSetGroupByTheme ( {commit}, groupByTheme ) {
    commit('OP_SET_GROUP_BY_THEME', groupByTheme)
  },

  opSetChatRoomGroups ( {state, commit}, chatRooms = null ) {
    if (!chatRooms) chatRooms = state.operator_panel.chatRooms;

    const { subjectList } = state.operator_panel;

    const subjectObj = {
      alias: null,
      id: 0,
      name: i18n.t('modules.op.texts.without_subject'),
      name_en: 'Without theme',
      name_ru: i18n.t('modules.op.texts.without_subject'),
      name_uk: i18n.t('modules.op.texts.without_subject'),
      parent_id: null,
      dialogs: [],
      childSubjectIds: [0],
    };

    //sort ChatRooms
    const chatRoomsWithoutSubject = [];
    const chatRoomsWithSubject = [];

		//add dialogs with theme which not available for User
		const found = (chatroom_subject_id) => subjectList?.some(el => el.id === chatroom_subject_id);

    const sortChatRooms = (chatRoom) => {
      chatRoom.subject_id === null || ( chatRoom.subject_id && !found(chatRoom.subject_id))
        ? chatRoomsWithoutSubject.push(chatRoom)
        : chatRoomsWithSubject.push(chatRoom);
    };

    chatRooms.forEach(sortChatRooms);

    if (chatRoomsWithoutSubject.length) {
      chatRoomsWithoutSubject.forEach(function (chatRoom) {
        subjectObj.dialogs.push(chatRoom)
      })
    }

    let subjectListWithDialogs = []

    if (chatRoomsWithSubject.length) {
      for (let subject of subjectList) {
        subject['dialogs'] = chatRoomsWithSubject.filter( ({subject_id}) => subject.id === subject_id )
      }
      subjectListWithDialogs = subjectList.filter( ({dialogs}) => dialogs.length > 0 )
    }

    const chatRoomGroups = [subjectObj, ...subjectListWithDialogs]

    commit('OP_SET_CHAT_ROOM_GROUPS', chatRoomGroups)
  },

  opAxiosClosedRoomsByClients ({commit}, query) {
    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/closed_rooms_by_clients/`;

    const params = query ? { params: query } : null;

    return axios
      .get(url, params)
      .then((response) => {
        return response.data?.data?.chat_rooms || [];
      })
      .catch((err) => {
        HELPERS.handleError([
          err,
          i18n.t('common.error'),
          i18n.t('common.error_get'),
        ]);
        return [];
      });
  },

  setClosedChatRoomsShow ({ commit }, value) {
    commit('OP_SET_CLOSED_CHAT_ROOMS_SHOW', value)
  },

  setSearchChatRoomsInput ( {commit}, value ) {
    commit('OP_SET_SEARCH_CHAT_ROOMS_INPUT', value)
  },

  setAxiosClosedRoomsSearch ({ commit, dispatch }, query) {
    dispatch("updateAjaxDialog", [true, true]);
    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/closed_rooms_search/`;

    //increase date_to to One day, for request on back
    if (query?.to) {
      let dayTo = new Date(query.to)
      query.to = new Date(dayTo.setDate(dayTo.getDate() + 1)).toISOString().slice(0, 10)
    }

    const params = query ? { params: query } : null;

    return axios
      .get(url, params)
      .then((response) => {

				commit('OP_SET_CLOSED_ROOMS_SEARCH', response.data?.data?.chat_rooms || [])

				dispatch("updateAjaxDialog", [false, false]);
        return response.data?.data?.chat_rooms || [];
      })
      .catch((err) => {
        HELPERS.handleError([
          err,
          i18n.t('common.error'),
          i18n.t('common.error_get'),
        ]);
        return [];
      });
  },

  setClosedRoomsSearchParams({ commit }, option) {
    commit('OP_SET_CLOSED_ROOMS_SEARCH_PARAMS', option)
  },

	setClosedRoomsSearchParamsToDefault({ commit }) {
		commit('OP_SET_CLOSED_ROOMS_SEARCH_PARAMS_TO_DEFAULT')
	},

	opAxiosTagsList({ commit, getters }, data) {
		let {chatTags, request} = {...data}
		const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/tags/list/${chatTags && getters.opActiveChatRoomFromState ? getters.opActiveChatRoomFromState : ''}`;
		if (!request) request = {
			search: '',
			limit: 100,
			offset: 0,
		};

		return axios
			.get(url, request)
			.then((response) => {
				commit('OP_SET_TAGS_LIST', response?.data?.data?.tags || [])
				return response?.data?.data?.tags || []
			})
			.catch((err) => {
				HELPERS.handleError([
					err,
					i18n.t('common.error'),
					i18n.t('common.error_get'),
				]);
				return [];
			});
	},

	setClosedChatRoomsDataByValue({ commit }, option) {
		commit('OP_SET_CLOSED_CHAT_ROOM_DATA_BY_VALUE', option)
	},

	setChatRoomFolders({commit}, data) {
		commit('OP_SET_CHAT_ROOM_FOLDERS', data)
	},
};

const getters = {
  opIsNotification: (state) => {
    const { notification } = state.operator_panel;

    return notification;
  },

  opChatRooms: (state) => {
    const { chatRooms } = state.operator_panel;

    const addDate = function addFormatDate(room) {

			//TODO: too many errors here
			if(!room?.createdAt) {
				console.log(room, 'opChatRooms getter with createdAt')
			}

      const date = room?.createdAt ? new Date(room.createdAt) : new Date();
      const formatDate = INTL.format(date);

      room.date = formatDate;
    };

    chatRooms.forEach(addDate);

    return chatRooms;
  },

  opChatRoomGroups2: (state) => {
    const { chatRooms, subjectList } = state.operator_panel;

    let chatRoomGroups = [];

    const subjectObj = {
        alias: null,
        id: 0,
        name: i18n.t('modules.op.texts.without_subject'),
        name_en: 'Without theme',
        name_ru: i18n.t('modules.op.texts.without_subject'),
        name_uk: i18n.t('modules.op.texts.without_subject'),
        parent_id: null,
        dialogs: [],
        childSubjectIds: [0],
    };

    //sort ChatRooms
    const chatRoomsWithoutSubject = [];
    const chatRoomsWithSubject = [];

    const sortChatRooms = (chatRoom) => {
      chatRoom.subject_id === null
        ? chatRoomsWithoutSubject.push(chatRoom)
        : chatRoomsWithSubject.push(chatRoom);
    };

    chatRooms.forEach(sortChatRooms);

    if (chatRoomsWithoutSubject.length) {
      chatRoomsWithoutSubject.forEach(function (chatRoom) {
        subjectObj.dialogs.push(chatRoom)
      })
      chatRoomGroups.push(subjectObj)
    }

    if (chatRoomsWithSubject.length) {

      chatRoomsWithSubject.forEach(function (chatRoom) {
        for (let i in subjectList) {
          if (subjectList[i].id === chatRoom.subject_id) {
            if (subjectList[i]?.dialogs === undefined) subjectList[i]['dialogs'] = []
            subjectList[i]?.dialogs.push(chatRoom)
            chatRoomGroups.push( subjectList[i] )
          }
        }
      })
    }

    return chatRoomGroups
  },

  opChatRoomsWithGroup: (state) => {
    const { chatRooms, subjectList } = state.operator_panel;
    const chatRoomsWithSubjects = chatRooms.filter( ({subject_id}) => subject_id != null )

    for (let subject of subjectList) {
      subject['dialogs'] = chatRoomsWithSubjects.filter( ({subject_id}) => subject.id === subject_id )
    }

    return subjectList.filter( (subject) => subject.dialogs.length > 0)
  },

  opChatRoomsWithoutGroup (state) {
    const { chatRooms } = state.operator_panel;
    return  chatRooms.filter( ({subject_id}) => subject_id === null )
  },

  opActiveChatGroup: (state, getters) => {
    const activeChatGroupId = state.operator_panel.activeChatGroup;

    const isEqualId = (group) => group.id === activeChatGroupId;

    // const activeGroup = getters.opChatRoomGroups.find(isEqualId);
    const activeGroup = state.operator_panel.chatRoomGroups.find(isEqualId);

    return activeGroup || null;
  },

  opActiveChatRoom: (state) => {
    const { chatRooms } = state.operator_panel;

    const activeChatRoomId = state.operator_panel.activeChatRoom;

    if (activeChatRoomId === null) {
      return null;
    }

    const isEqualId = (room) => room.id === activeChatRoomId;

    const activeChatRoom = chatRooms.find(isEqualId);

    return activeChatRoom || null;
  },

  opActiveChatRoomFromState: (state) => state.operator_panel.activeChatRoom,

  opServerTime: (state) => state.operator_panel.serverTime,

  opSubjectList: (state) => state.operator_panel.subjectList,

  opSubjectListTree: (state) => state.operator_panel.subjectListTree,

  opRebuildKey: (state) => state.operator_panel.rebuildKey,

  opTypeChatRooms: (state) => state.operator_panel.typeChatRooms,
  opTypeChatRoomsEnum: (state) => state.operator_panel.typeChatRoomsEnum,

  opGroupByTheme: (state) => state.operator_panel.groupByTheme,

  opChatRoomGroupsNew: (state) => state.operator_panel.chatRoomGroups,

  opClosedChatRoomsShow: (state) => state.operator_panel.closedChatRoomsShow,

  opSearchChatRoomsInput: (state) => state.operator_panel.searchChatRoomsInput,

  /**
   * @name opFilteredChatRooms
   * @description main function for filter chat rooms by search and type
   * @param state
   * @param getters
   * @returns filtered array of chatRooms
   */
  opFilteredChatRooms: (state, getters) => {
    const searchVal = (getters.opSearchChatRoomsInput === null)
      ? ''
      : getters.opSearchChatRoomsInput.toLowerCase();
    const dialogs = getters.opChatRooms || [];

    const isClient = (participant) => participant.type === 'client';
    const isUser = (participant) => participant.type === 'user';

    const concatInfo = (acc, infoValue) => acc + infoValue;

    const isEqualSearch = (client) => {
      const clientInfoObj = client.client || {};
      const clientInfo = Object.values(clientInfoObj).reduce(
        concatInfo,
        ''
      );
      const normClientInfo = clientInfo.toLowerCase();

      return normClientInfo.includes(searchVal);
    };

    const compareClientFullName = (dialog) => {
      const clients = dialog.participants.filter(isClient);
      return clients.some(isEqualSearch);
    };

		const compareChatTitle = (dialog) => {
			return dialog?.kw_chat_title?.includes(searchVal) || false
		};

    const userId = getters.opUserInfo.id;

    const isEqualUserId = (user) => user.user_id === userId;

    //filter for unassigned dialogs
    const compareDialogUnassigned = (dialog) => {
      const users = dialog.participants.filter( ({type}) => type === 'user' );
      return users.length <= 0;
    };

    //filter assigned to current user
    const compareDialogUser = (dialog) => {
      const users = dialog.participants.filter(isUser);
      return users.some(isEqualUserId);
    };

		//filter Ticket is assigned to current user
		const compareTicketUser = (dialog) => {
			return dialog?.type === 'ticket' && dialog?.chatroom_ticket?.assignee_id === userId;
		};
		const compareOutdated = (dialog) => {
			return dialog?.type === 'ticket' && dialog?.chatroom_ticket?.outdated;
		};
		const compareResolved = (dialog) => {
			return dialog?.type === 'ticket' && dialog?.chatroom_ticket?.resolved;
		};

		//filter assigned to current user
		const compareDialogQueue = (dialog) => {
			return dialog.status === 'queue';
		};

    //filter assigned to user by ID
    const compareDialogUserById = (dialog, userId) => {
      const users = dialog.participants.filter(isUser);
      return users.some((user) => user.user_id === userId);
    };

    const compareDialogSubjectById = (dialog, subjectId) => {
      return (subjectId === 0)
        ? dialog.subject_id === null
        : dialog.subject_id === subjectId
    };

		const compareDialogChannelsByName = (dialog, channelName) => {
			return dialog.kw_channel === channelName
		};

		const compareDialogBotById = (dialog, bot_id) => {
			return dialog.bot_id === bot_id
		};

		const compareDialogStatusByName = (dialog, status) => {
			return dialog.status_name === status
		};

		const compareDialogTagByName = (dialog, tagName) => {
			return dialog.chatroom_tags?.some((tag) => tag.name === tagName);
		};

    const ifTested = (dialog) => {
      const ifPassFullName =
        searchVal === '' || compareClientFullName(dialog) || compareChatTitle(dialog);

      //filter from ChatsMenu.vue
      let isPassTypeCheck
      switch (getters.opTypeChatRooms?.type) {
        case getters.opTypeChatRoomsEnum.all:
          isPassTypeCheck = true
          break;
        case getters.opTypeChatRoomsEnum.unassigned:
          isPassTypeCheck = compareDialogUnassigned(dialog)
          break;
        case getters.opTypeChatRoomsEnum.my:
          isPassTypeCheck = compareDialogUser(dialog)
          break;
				case getters.opTypeChatRoomsEnum.ticket_assignee_my:
          isPassTypeCheck = compareTicketUser(dialog)
          break;
				case getters.opTypeChatRoomsEnum.outdated:
					isPassTypeCheck = compareOutdated(dialog)
					break;
				case getters.opTypeChatRoomsEnum.resolved:
					isPassTypeCheck = compareResolved(dialog)
					break;
				case getters.opTypeChatRoomsEnum.queue:
					isPassTypeCheck = compareDialogQueue(dialog)
					break;
        case getters.opTypeChatRoomsEnum.operator:
          isPassTypeCheck = compareDialogUserById(dialog, getters.opTypeChatRooms.id)
          break;
        case getters.opTypeChatRoomsEnum.subject:
          isPassTypeCheck = compareDialogSubjectById(dialog, getters.opTypeChatRooms.id)
          break;
				case getters.opTypeChatRoomsEnum.channel:
					isPassTypeCheck = compareDialogChannelsByName(dialog, getters.opTypeChatRooms.id)
					break;
				case getters.opTypeChatRoomsEnum.bot:
					isPassTypeCheck = compareDialogBotById(dialog, getters.opTypeChatRooms.id)
					break;
				case getters.opTypeChatRoomsEnum.status:
					isPassTypeCheck = compareDialogStatusByName(dialog, getters.opTypeChatRooms.id)
					break;
				case getters.opTypeChatRoomsEnum.tag:
					isPassTypeCheck = compareDialogTagByName(dialog, getters.opTypeChatRooms.id)
					break;
        default:
          isPassTypeCheck = false
      }

      return ifPassFullName && isPassTypeCheck;
    };

    let filteredDialogs = dialogs.filter(ifTested);

		//sorting
		//default sorting
    const byDateDesc = (a, b) => {
      const aLastMsg = a.messages[a.messages.length - 1];
      const aLastMsgTime = aLastMsg?.send_datetime;
      const aDialogTime = a.start_datetime;
      const aStartTime = aLastMsgTime || aDialogTime;

      const bLastMsg = b.messages[b.messages.length - 1];
      const bLastMsgTime = bLastMsg?.send_datetime;
      const bDialogTime = b.start_datetime;
      const bStartTime = bLastMsgTime || bDialogTime;

      const aDate = new Date(aStartTime);
      const bDate = new Date(bStartTime);

      const aTime = aDate.getTime();
      const bTime = bDate.getTime();

      return aTime - bTime;
    };

		//by created(id) sorting
		const byCreatedDesc = (a, b) => {
			return a.id - b.id;
		}

		//by last user added sorting
		const byUserDesc = (a, b) => {
			const aUsers = a?.participants?.filter(item => item?.user_id) || []
			const aUserId = aUsers.length
				? aUsers?.reduce(function(prev, current) {
					return (prev && prev.id > current.id) ? prev : current
				}).id
				: 0

			const bUsers = b?.participants?.filter(item => item?.user_id) || []
			const bUserId = bUsers.length
				? bUsers?.reduce(function(prev, current) {
					return (prev && prev.id > current.id) ? prev : current
				}).id
				: 0

			return aUserId - bUserId
		}

		//by message read sorting
		const byReadDesc = (a, b) => {
			const aLastMsg = a.messages[a.messages.length - 1];
			const aMessageRead = aLastMsg?.message_read ? 0 : 1

			const bLastMsg = b.messages[b.messages.length - 1];
			const bMessageRead = bLastMsg?.message_read ? 0 : 1

			return aMessageRead - bMessageRead
		}


		function switchSorting(sortingMode) {
			switch (sortingMode) {
				case 'default':
					return filteredDialogs.sort(byDateDesc).reverse();
				case 'created':
					return filteredDialogs.sort(byCreatedDesc).reverse();
				case 'user':
					return filteredDialogs.sort(byUserDesc).reverse();
				case 'message_read':
					filteredDialogs = filteredDialogs.sort(byDateDesc)
					return filteredDialogs.sort(byReadDesc).reverse();
				default:
					return filteredDialogs.sort(byDateDesc).reverse();
			}
		}

		return switchSorting(state.operator_panel.chatRoomsSorting)
  },

  opClosedRoomsSearch: (state) => state.operator_panel.closedRoomsSearch,
  opClosedRoomsSearchParams: (state) => state.operator_panel.closedRoomsSearchParams,
	opClosedRoomsSearchParamsDefault: (state) => state.operator_panel.closedRoomsSearchParamsDefault,
  opClosedRoomsSearchParamGroupByEnum: (state) => state.operator_panel.closedRoomsSearchParamGroupByEnum,
  opTagsList: (state) => state.operator_panel.tagsList,
	opClosedChatRoomData: (state) => state.operator_panel.closedChatRoomData,

	opChatRoomFolders: (state) => state.operator_panel.chatRoomFolders,
	opChatRoomsSortingEnum: (state) => state.operator_panel.chatRoomsSortingEnum,

	opChatRoomsSorting: (state) => state.operator_panel.chatRoomsSorting,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
