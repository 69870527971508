<template>
  <svg :width="width" :height="height" viewBox="0 0 149 205" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M43 51C43 52.6569 44.3453 54 46.0048 54H102.995C104.655 54 106 52.6569 106 51C106 49.3431 104.655 48 102.995 48H46.0048C44.3453 48 43 49.3431 43 51Z" :fill="iconColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M33.6301 88.7585C33.2613 88.7585 32.9623 88.4455 32.9623 88.0595V79.9405C32.9623 79.5545 33.2613 79.2415 33.6301 79.2415H115.37C115.739 79.2415 116.038 79.5544 116.038 79.9405V88.0595C116.038 88.4456 115.739 88.7585 115.37 88.7585H33.6301ZM27 88.0595C27 91.8926 29.9684 95 33.6301 95H115.37C119.032 95 122 91.8926 122 88.0595V79.9405C122 76.1074 119.032 73 115.37 73H33.6301C29.9684 73 27 76.1074 27 79.9405V88.0595Z" :fill="iconColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M33.6301 122.758C33.2608 122.758 32.9623 122.445 32.9623 122.06V113.941C32.9623 113.555 33.2613 113.242 33.6301 113.242H115.37C115.739 113.242 116.038 113.555 116.038 113.941V122.06C116.038 122.445 115.739 122.758 115.37 122.758H33.6301ZM27 122.06C27 125.893 29.9688 129 33.6301 129H115.37C119.031 129 122 125.893 122 122.06V113.941C122 110.107 119.032 107 115.37 107H33.6301C29.9684 107 27 110.107 27 113.941V122.06Z" :fill="iconColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M67.4275 158.996C65.0272 158.996 63.0811 157.074 63.0811 154.704V154.296C63.0811 151.926 65.0272 150.004 67.4275 150.004H81.5721C83.9726 150.004 85.9189 151.926 85.9189 154.296V154.704C85.9189 157.074 83.9726 158.996 81.5721 158.996H67.4275ZM57 154.704C57 160.39 61.6685 165 67.4275 165H81.5721C87.3309 165 92 160.391 92 154.704V154.296C92 148.609 87.3309 144 81.5721 144H67.4275C61.6685 144 57 148.61 57 154.296V154.704Z" :fill="iconColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 33.0268C0 34.6689 1.34312 36 2.99995 36H146C147.657 36 149 34.6689 149 33.0268V11.4796C149 5.13957 143.814 0 137.417 0H11.5826C5.18577 0 0 5.13967 0 11.4796V33.0268ZM5.9999 30.0536V11.4796C5.9999 8.42356 8.49961 5.94635 11.5826 5.94635H137.417C140.5 5.94635 143 8.42365 143 11.4796V30.0536H5.9999Z" :fill="iconColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6125 183.994C8.52114 183.994 6.01517 181.491 6.01517 178.405V57.0032C6.01517 55.3446 4.66863 54 3.00758 54C1.34654 54 0 55.3446 0 57.0032V178.405C0 184.809 5.19906 190 11.6125 190H88.9924C90.6535 190 92 188.655 92 186.997C92 185.338 90.6535 183.994 88.9924 183.994H11.6125Z" :fill="iconColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M137.995 187.169C138.64 188.694 140.401 189.407 141.926 188.762C146.078 187.007 149 182.896 149 178.096V11.577C149 5.1832 143.814 0 137.417 0H11.583C5.18634 0 0 5.18309 0 11.577V44.8949C0 46.5508 1.34312 47.8932 2.99994 47.8932C4.65677 47.8932 5.99989 46.5508 5.99989 44.8949V11.577C5.99989 8.49522 8.49981 5.9968 11.583 5.9968H137.417C140.501 5.9968 143 8.49513 143 11.577V178.096C143 180.403 141.599 182.389 139.589 183.239C138.063 183.884 137.349 185.644 137.995 187.169Z" :fill="iconColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M22 21.1164C20.2789 21.1164 18.8836 19.7211 18.8836 18C18.8836 16.2789 20.2789 14.8836 22 14.8836C23.7211 14.8836 25.1164 16.2789 25.1164 18C25.1164 19.7211 23.7211 21.1164 22 21.1164ZM13 18C13 22.9705 17.0295 27 22 27C26.9705 27 31 22.9705 31 18C31 13.0295 26.9705 9 22 9C17.0295 9 13 13.0295 13 18Z" :fill="iconColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M46 21.1164C44.2789 21.1164 42.8836 19.7211 42.8836 18C42.8836 16.2789 44.2789 14.8836 46 14.8836C47.7211 14.8836 49.1164 16.2789 49.1164 18C49.1164 19.7211 47.7211 21.1164 46 21.1164ZM37 18C37 22.9705 41.0295 27 46 27C50.9705 27 55 22.9705 55 18C55 13.0295 50.9705 9 46 9C41.0295 9 37 13.0295 37 18Z" :fill="iconColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M70 21.1164C68.2789 21.1164 66.8836 19.7211 66.8836 18C66.8836 16.2789 68.2789 14.8836 70 14.8836C71.7211 14.8836 73.1164 16.2789 73.1164 18C73.1164 19.7211 71.7211 21.1164 70 21.1164ZM61 18C61 22.9705 65.0295 27 70 27C74.9705 27 79 22.9705 79 18C79 13.0295 74.9705 9 70 9C65.0295 9 61 13.0295 61 18Z" :fill="iconColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M123.696 198.754L129.268 195.501L123.558 185.502C122.061 182.881 122.95 179.53 125.542 178.017C125.541 178.017 125.542 178.017 125.542 178.017L132.174 174.146L103.948 157.67V190.621L110.582 186.749C113.174 185.236 116.488 186.133 117.985 188.755C117.985 188.755 117.985 188.755 117.985 188.755L123.696 198.754ZM133.49 174.914C133.49 174.914 133.49 174.914 133.49 174.914V174.914ZM133.49 173.377C133.49 173.377 133.49 173.377 133.49 173.377V173.377ZM129.81 195.185C129.81 195.185 129.81 195.185 129.81 195.185L129.81 195.185ZM118.857 202.309C120.327 204.883 123.583 205.763 126.128 204.278L132.784 200.393C132.784 200.393 132.784 200.393 132.784 200.393C135.33 198.907 136.202 195.616 134.732 193.043L128.973 182.958L136.464 178.586C139.845 176.612 139.845 171.679 136.464 169.705L105.607 151.694C102.227 149.722 98 152.186 98 156.134V192.157C98 196.103 102.226 198.571 105.607 196.597C105.607 196.597 105.607 196.597 105.607 196.596L113.098 192.225L118.857 202.309C118.857 202.309 118.857 202.309 118.857 202.309ZM112.834 191.761C112.834 191.761 112.834 191.761 112.834 191.761V191.761Z" :fill="iconColor"/>
  </svg>
</template>

<script>
  export default {
    props: {
      iconName: {
        type: String,
        default: 'iconFillPage'
      },
      width: {
        type: [Number, String],
        default: 149
      },
      height: {
        type: [Number, String],
        default: 205
      },
      iconColor: {
        type: String,
        default: 'currentColor',
      }
    }
  }
</script>