import LAYOUT from '@/layout/layout';
import moduleLayout from '@/modules/OperatorPanel/layout/SettingsLayout.vue';

export default [
  {
    path: '/operator_settings',
    component: LAYOUT.base,
    redirect: '/operator_settings/users',
    children: [
      {
        path: 'users',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'users',
            component: () =>
              import(
                /* webpackChunkName: 'op-users' */ '@/modules/OperatorPanel/views/settings/UsersTab.vue'
              ),
          },
        ],
      },
      // {
      //   path: 'subjects',
      //   component: moduleLayout,
      //   children: [
      //     {
      //       path: '',
      //       name: 'subjects',
      //       component: () =>
      //         import(
      //           /* webpackChunkName: 'op-subjects' */ '@/modules/OperatorPanel/views/settings/SubjectsTab.vue'
      //         ),
      //     },
      //   ],
      // },
      {
        path: 'skill_groups',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'skillGroups',
            component: () =>
              import(
                /* webpackChunkName: 'op-skill-groups' */ '@/modules/OperatorPanel/views/settings/SkillGroupsTab.vue'
              ),
          },
        ],
      },
      {
        path: 'quick_reply_categories',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'fastAnswersCategories',
            component: () =>
              import(
                /* webpackChunkName: 'op-quick-reply-categories' */ '@/modules/OperatorPanel/views/settings/QuickReplyCategories.vue'
              ),
          },
        ],
      },
      {
        path: 'quick_replies',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'fastAnswers',
            component: () =>
              import(
                /* webpackChunkName: 'op-quick-replies' */ '@/modules/OperatorPanel/views/settings/QuickReplies.vue'
              ),
          },
        ],
      },
      {
        path: 'statuses',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'statuses',
            component: () =>
              import(
                /* webpackChunkName: 'op-quick-replies' */ '@/modules/OperatorPanel/views/settings/Statuses.vue'
              ),
          },
        ],
      },
      {
        path: 'settings',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'settings',
            component: () =>
              import(
                /* webpackChunkName: 'op-settings' */ '@/modules/OperatorPanel/views/settings/SettingsTab.vue'
              ),
          },
        ],
      },
      {
        path: 'chat_statuses',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'chatStatuses',
            component: () =>
              import(
                /* webpackChunkName: 'op-quick-replies' */ '@/modules/OperatorPanel/views/settings/ChatStatusesTab.vue'
                ),
          },
        ],
      },
			{
				path: 'tags',
				component: moduleLayout,
				children: [
					{
						path: '',
						name: 'tags',
						component: () =>
							import(
								/* webpackChunkName: 'op-skill-groups' */ '@/modules/OperatorPanel/views/settings/TagsTab.vue'
								),
					},
				],
			},
			{
				path: 'priorities',
				component: moduleLayout,
				children: [
					{
						path: '',
						name: 'priorities',
						component: () =>
							import(
								/* webpackChunkName: 'op-skill-groups' */ '@/modules/OperatorPanel/views/settings/PrioritiesTab.vue'
								),
					},
				],
			},
    ],
  },
];
