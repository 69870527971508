<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.9602 0.8158C13.312 0.16752 12.264 0.16752 11.6158 0.8158L10.7248 1.70386L13.6228 4.60188L14.5138 3.71086C15.1621 3.06258 15.1621 2.01468 14.5138 1.36639L13.9602 0.8158ZM5.10336 7.32821C4.92279 7.50878 4.78366 7.73079 4.70373 7.97649L3.82752 10.6051C3.74167 10.8597 3.80976 11.1409 3.99921 11.3333C4.18866 11.5258 4.46988 11.5909 4.72742 11.505L7.35606 10.6288C7.5988 10.5489 7.82081 10.4098 8.00434 10.2292L12.9567 5.27384L10.0558 2.37286L5.10336 7.32821ZM2.84178 2.06796C1.27288 2.06796 0 3.34084 0 4.90974V12.4878C0 14.0567 1.27288 15.3296 2.84178 15.3296H10.4199C11.9888 15.3296 13.2616 14.0567 13.2616 12.4878V9.64603C13.2616 9.12208 12.8383 8.69878 12.3144 8.69878C11.7904 8.69878 11.3671 9.12208 11.3671 9.64603V12.4878C11.3671 13.0118 10.9438 13.4351 10.4199 13.4351H2.84178C2.31783 13.4351 1.89452 13.0118 1.89452 12.4878V4.90974C1.89452 4.38578 2.31783 3.96248 2.84178 3.96248H5.68356C6.20751 3.96248 6.63082 3.53917 6.63082 3.01522C6.63082 2.49127 6.20751 2.06796 5.68356 2.06796H2.84178Z" :fill="iconColor"/>
  </svg>
</template>

<script>
    export default {
        props: {
            iconName: {
                type: String,
                default: 'iconEditPencil'
            },
            width: {
                type: [Number, String],
                default: 15
            },
            height: {
                type: [Number, String],
                default: 16
            },
            iconColor: {
                type: String,
                default: 'currentColor'
            }
        }
    }
</script>