<template>
  <v-sheet
      elevation="0"
      color="white"
      height="100%"
      class="d-flex flex-column"
  >
    <slot name="top"></slot>

    <div
      v-if="!$slots.top || hiddenTitle"
      class="filter-title"
    >{{ $t('modules.customdata.filters.title') }}</div>

    {{ /* Filters Block */ }}
    <div class="filter-body">
      <vue-scroll :ops="ops">

        <template v-if="selectedFiltersArray.length || savedFiltersList.length">
          <DynamicFiltersSave
            :class="{
              'bg_transparent': $slots.top
            }"
            :selectedFilters="selectedFiltersArray"
            :custom_selected_filters="selected_filters"
            :savedFilters="savedFiltersList"
            @clear-all-filters="clearAllFilters"
            @save-filter="saveFilter($event)"
            @delete-saved-filter="deleteSavedFilter($event)"

            :action_toggle_active_filter="action_toggle_active_filter"
            :action_delete_selected_filter_in_array="action_delete_selected_filter_in_array"

            :canSave="canSave"
          />
        </template>

        <v-divider></v-divider>

        <div class="ct__filters">

          <v-expansion-panels
              accordion
              multiple
              tile
              class="ct__filters--accordions"
              v-model="panelModel"
          >
            <v-expansion-panel
                v-for="(filter, index) of filtersList"
                :key="index"
            >
              <v-expansion-panel-header><span>{{ returnFilterKeyOrLabel(filter) }}</span></v-expansion-panel-header>
              <v-expansion-panel-content>
                <DynamicFilter
                  :filter-data="filter"
                  :selected_filters="selected_filters"
                  :custom_linked_tables_values="custom_linked_tables_values"

                  :action_set_selected_filter_value="action_set_selected_filter_value"
                  :action_set_selected_filter_condition="action_set_selected_filter_condition"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>

      </vue-scroll>
    </div>
    {{ /* end Filters Block */ }}

    <v-divider></v-divider>

    {{ /* Apply Filters Button */ }}
    <div class="pa-4">
      <v-btn
          color="primary"
          height="38"
          dense
          elevation="0"
          block
          @click="applyFilters"
      >
        {{ $t('modules.customdata.filters.apply_filters') }}
      </v-btn>
    </div>
    {{ /* Apply Filters Button */ }}
  </v-sheet>
</template>

<script>
  import DynamicFilter from "./DynamicFilter";
  import DynamicFiltersSave from "./DynamicFiltersSave";
  import i18n from "@/lang/i18n";
  import {mapGetters} from "vuex";

  export default  {
    name: 'DynamicFilters',

    props: {
      hiddenTitle: {
        type: Boolean,
        default: false,
      },
      filtersList: {
        type: Array,
        default: () => [],
      },
      savedFiltersList: {
        type: Array,
        default: () => [],
      },
      linkedTablesValues: {
        type: Object,
        default: () => {},
      },
      selected_filters: {
        type: Object,
        default: () => {},
      },
      action_toggle_active_filter: {
        type: String,
        default: ''
      },
      action_delete_selected_filter_in_array: {
        type: String,
        default: ''
      },
      action_selected_filters_empty: {
        type: String,
        default: ''
      },
      custom_linked_tables_values: {
        type: Object,
        default: () => {},
      },
      action_set_selected_filter_value: {
        type: String,
        default: '',
      },
      action_set_selected_filter_condition: {
        type: String,
        default: '',
      },
      canSave: {
        type: Boolean,
        default: true,
      }
    },

    components: {
      DynamicFilter,
      DynamicFiltersSave,
    },

    data: () => ({
      i18n,

      //filters saved
      toggleActiveFilter: undefined,
      dialogSaveFilter: false, //filter open dialog
      filterTitle: '', //saved filter input

      savedFilters: [],
      savedFilterChanging: false,

      //filter scrollbar
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          size: '2px',
          gutterOfSide: '0',
          specifyBorderRadius: '0',
        },
        bar: {
          size: '2px',
          background: '',
        },
      },

      selectedFilters: {},
      selectedSavedFilters: [],
      deleteFiltersCheckboxes: [],
      deleteFiltersStrings: null,
      deleteFiltersDateTime: null,

      // array with panels index for auto open panels
      panelModel: [],

      selectedFilterCondition: {},
      selectedFiltersArray: [],
    }),

    mounted() {
      //set color for scroller
      this.ops.bar.background =
        this.$vuetify.theme.themes?.light?.primary || '#E0E0E0'
    },

    computed: {
      ...mapGetters([]),
    },

    methods: {
      applyFilters() {
        this.$emit('apply');
      },

      clearAllFilters() {
        this.$store.dispatch(this.action_toggle_active_filter, null)
        this.$store.dispatch(this.action_selected_filters_empty)
        this.$emit('apply');
        this.$emit('reset-columns');
      },

      saveFilter(data) {
        let { name, with_columns } = data
        this.$emit('save', { name, with_columns });
      },

      deleteSavedFilter(id){
        this.$emit('delete-filter', id);
      },

      returnFilterKeyOrLabel(item) {
        return item?.label ? item?.label : item.name
      },
    },

    created() {
      this.$root.$on('clear-all-filters', () => {
        this.clearAllFilters()
      });
    },

    watch: {
      selected_filters: {
        handler(value) {
          this.selectedFiltersArray = Object.entries(value)
            .filter(item => item[1]?.value)
            .map(entry => { return {[entry[0]]: entry[1]} })
        },
        deep: true,
      },

      filtersList: function(afterVal, beforeVal) {
        if (beforeVal !== afterVal) this.panelModel = []
      },
    },
  };
</script>

<style lang="scss" scoped>
  /*** Filters ***/
  .filter-title {
    padding: 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  .filter-body::v-deep {
    height: 300px;
    flex: auto;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 2px;
      height: 100%;
      background-color: var(--v-gray-base);
      z-index: 1;
    }

    .__rail-is-vertical {
      z-index: 2 !important;
    }
  }

  .ct__filters {
    //height: 300px;
    max-width: 200px;
    flex: auto;

    .__rail-is-vertical {
      width: 2px !important;
    }

    .v-expansion-panels::v-deep {
      .v-expansion-panel::before {
        box-shadow: none;
      }

      .v-expansion-panel-header {
        padding: 12px 16px;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;

        span{
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      [aria-expanded="true"] {
        .v-expansion-panel-header {
          min-height: 48px;
        }

        .v-expansion-panel-header__icon .v-icon {
          color: var(--black-base);
        }
      }

      .v-expansion-panel-content {
        .v-expansion-panel-content__wrap {
          padding: 10px 16px 16px;
          //margin-top: -10px;
        }
      }
    }

    /* Checkboxes */
    .v-input--radio-group::v-deep,
    .v-input--checkbox::v-deep {
      .v-input--dense {
        margin-top: 6px;
      }

      .v-icon {
        font-size: 24px;
        color: var(--v-primary-base);
      }

      .theme--light.v-label {
        color: var(--v-black-base);
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .custom-data__filters {
    &-number{
      max-width: 90px
    }
    &-boolean::v-deep {
      margin-top: 0;
      label {
        display: block !important;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    &--panel{
      color: white;
    }
    &--actions{
      display: flex;
    }
  }

  /*** end Filters ***/
</style>
